import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthModule } from '../@auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CadastraisComponent } from './cadastrais/cadastrais.component';
import { CertidoesComponent } from './certidoes/certidoes.component';
import { CreditoComponent } from './credito/credito.component';
import { DashboardComponent } from './dashBoard/dashboard.component';
import { DossieComponent } from './dossie/dossie.component';
import { HistoricoComponent } from './historico/historico.component';
import { PatrimonialComponent } from './patrimonial/patrimonial.component';
import { ProcessoComponent } from './processo/processo.component';
import { ComponentsModule } from '../components/components.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { TeiaRelacionamentoComponent } from './teia-relacionamento/teia-relacionamento.component';
import { MenuTeiaRelacionamentoComponent } from './teia-relacionamento/menu-teia-relacionamento/menu-teia-relacionamento.component';
import { ModalConfirmacaoComponent } from './teia-relacionamento/modal-confirmacao/modal-confirmacao.component';
import { ModalMinhasPesquisasComponent } from './teia-relacionamento/menu-teia-relacionamento/acoes-menu/modal-minhas-pesquisas/modal-minhas-pesquisas.component';
import { VisaoProcessualComponent } from './dashBoard/visao-processual/visao-processual.component';
import { ConsultasRealizadasComponent } from './dashBoard/consultas-realizadas/consultas-realizadas.component';
import { LoadingComponent } from '../components/view/loader/loader';
import { CurrencyMaskModule } from "ng2-currency-mask";

// import { NgxGraphModule } from '@swimlane/ngx-graph';
import { InformacoesAdicionaisComponent } from './teia-relacionamento/menu-teia-relacionamento/acoes-menu/informacoes-adicionais/informacoes-adicionais.component';
import { ModalConfirmacaoNirfComponent } from './certidoes/modal-confirmacao-nirf/modal-confirmacao-nirf.component';
import { ModalGrupoPesquisaComponent } from './teia-relacionamento/menu-teia-relacionamento/acoes-menu/modal-grupo-pesquisa/modal-grupo-pesquisa.component';
import { EncontrarDocComponent } from './encontrar-doc/encontrar-doc.component';
import { ContratoClienteComponent } from './dashBoard/contrato-cliente/contrato-cliente.component';
import { ModalValoresAdicionaisComponent } from './patrimonial/modal-valores-adicionais/modal-valores-adicionais.component';
import { RelatorioUsoComponent } from './dashBoard/relatorio-uso/relatorio-uso.component';
import { PrecificadorCreditoComponent } from './precificador-credito/precificador-credito.component';
import { PrecificadorListagemComponent } from './precificador-credito/precificador-listagem/precificador-listagem.component';
import { TabelaComponent } from './dashBoard/tabela/tabela.component';
import { VisaoProcessoComponent } from './processo/visao-processo/visao-processo.component';
import { LinhaTempoComponent } from './processo/visao-processo/linha-tempo/linha-tempo.component';
import { ModalAdicionarGraficoComponent } from './teia-relacionamento/menu/modal-adicionar-grafico/modal-adicionar-grafico.component';
import { GraficoTipoLinhaComponent } from './processo/visao-processo/linha-tempo/grafico-tipo-linha/grafico-tipo-linha.component';
// import { VisaoTelaModule } from '../components/view/visao-em-tela/visao-em-tela.module';
import { ResultadoLocalizadorComponent } from './encontrar-doc/resultado-localizador/resultado-localizador.component';
import { CruzamentoDadosComponent } from './cruzamento-dados/cruzamento-dados.component';
import { CruzamentoTelaComponent } from './cruzamento-dados/cruzamento-tela/cruzamento-tela.component';
import { ModalValidacaoCruzamentoComponent } from './cruzamento-dados/modal-validacao-cruzamento/modal-validacao-cruzamento.component';
import { ModalDadosComponent } from './cruzamento-dados/cruzamento-tela/modal-dados/modal-dados.component';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    CadastraisComponent,
    CertidoesComponent,
    CreditoComponent,
    DashboardComponent,
    DossieComponent,
    HistoricoComponent,
    PatrimonialComponent,
    ProcessoComponent,
    NotFoundComponent,
    TeiaRelacionamentoComponent,
    MenuTeiaRelacionamentoComponent,
    ModalConfirmacaoComponent,
    ModalMinhasPesquisasComponent,
    VisaoProcessualComponent,
    ConsultasRealizadasComponent,
    LoadingComponent,
    InformacoesAdicionaisComponent,
    ModalConfirmacaoNirfComponent,
    ModalGrupoPesquisaComponent,
    EncontrarDocComponent,
    ContratoClienteComponent,
    ModalValoresAdicionaisComponent,
    RelatorioUsoComponent,
    TabelaComponent,
    VisaoProcessoComponent,
    LinhaTempoComponent,
    ModalAdicionarGraficoComponent,
    GraficoTipoLinhaComponent,
    ResultadoLocalizadorComponent,
    CruzamentoDadosComponent,
    CruzamentoTelaComponent,
    ModalValidacaoCruzamentoComponent,
    ModalDadosComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    BrowserModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    // VisaoTelaModule,
    CurrencyMaskModule,
    ComponentsModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxMaskModule.forRoot(),
    // NgxGraphModule
  ],
  exports:[
    CadastraisComponent,
    CertidoesComponent,
    CreditoComponent,
    DashboardComponent,
    DossieComponent,
    HistoricoComponent,
    PatrimonialComponent,
    ProcessoComponent,
    // LinhaTempoComponent,
    // GraficoTipoLinhaComponent
    ResultadoLocalizadorComponent,
    TeiaRelacionamentoComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },DecimalPipe],
})
export class ViewsModule { }
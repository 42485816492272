<div *ngIf="exibirDadosCadastrais"
  class="row d-flex justify-content-center border-grey align-items-center p-3 info-content">

  <div class="col p-4" *ngIf="dadosAba.novaPesquisa && !isAnaliseCredito">
    <loader-only class="mt-3" *ngIf="isLoadingTelefones"></loader-only>
    <div *ngIf="!isLoadingTelefones">
      <h2 class="title-tab" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">{{infoCadastraisTelefone.dadosCpf.nome}}
      </h2>
      <h2 class="title-tab" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">
        {{infoCadastraisTelefone.dadosCnpj.razaoSocial}}</h2>
      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">CPF:
        <span>{{infoCadastraisTelefone.dadosCpf.cpf}}</span></p>
      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">CNPJ:
        <span>{{infoCadastraisTelefone.dadosCnpj.cnpj}}</span></p>

      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Nascimento:
        <span>{{infoCadastraisTelefone.dadosCpf.dataNascimento | date: 'dd/MM/yyyy'}}</span></p>
      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">CNAE:
        <span>{{infoCadastraisTelefone.dadosCnpj.cnaeFiscal}} - {{infoCadastraisTelefone.dadosCnpj.cnaeNome}}</span>
      </p>

      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Filiação:
        <span>{{infoCadastraisTelefone.dadosCpf.filiacao}}</span></p>
      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Capital Social: <span
          class="capital-social">{{infoCadastraisTelefone.dadosCnpj.capitalSocial}}</span></p>

      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Óbito:
        <span>{{infoCadastraisTelefone.dadosCpf.obitoIndicador}}</span></p>
      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Data de inicio das atividades:
        <span>{{infoCadastraisTelefone.dadosCnpj.dataInicioAtividades}}</span></p>

      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 1">Situação:
        <span>{{infoCadastraisTelefone.dadosCpf.situacaoCpf}}</span></p>
      <p class="info-paragraph" *ngIf="infoCadastraisTelefone.tipoDocumento == 2">Situação:
        <span>{{infoCadastraisTelefone.dadosCnpj.situacaoCadastral}}</span></p>

    </div>
  </div>

  <div class="col p-4" *ngIf="!dadosAba.novaPesquisa">
    <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
    <div *ngIf="!isLoadingCadastrais">
      <h2 class="title-tab" *ngIf="documento.length == 11">{{infoCadastrais.nome}}</h2>
      <h2 class="title-tab" *ngIf="documento.length == 14">{{infoCadastrais.razaoSocial}}</h2>
      <p class="info-paragraph" *ngIf="documento.length == 11">CPF: <span>{{infoCadastrais.cpf}}</span></p>
      <p class="info-paragraph" *ngIf="documento.length == 14">CNPJ: <span>{{infoCadastrais.cnpj}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Nascimento: <span>{{infoCadastrais.dataNascimento |
            date: 'dd/MM/yyyy'}}</span></p>
      <p class="info-paragraph" *ngIf="documento.length == 14">CNAE: <span>{{infoCadastrais.cnaeFiscal}} -
          {{infoCadastrais.cnaeNome}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Filiação: <span>{{infoCadastrais.filiacao}}</span></p>
      <p class="info-paragraph" *ngIf="documento.length == 14">Capital Social:
        <span>{{infoCadastrais.capitalSocial}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Óbito: <span>{{infoCadastrais.obitoIndicador}}</span>
      </p>
      <p class="info-paragraph" *ngIf="documento.length == 14">Data de inicio das atividades:
        <span>{{infoCadastrais.dataInicioAtividades | date: 'dd/MM/yyyy'}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Situação: <span>{{infoCadastrais.situacaoCpf}}</span>
      </p>
      <p class="info-paragraph" *ngIf="documento.length == 14">Situação:
        <span>{{infoCadastrais.situacaoCadastral}}</span></p>

    </div>
  </div>

  <div class="col p-4" *ngIf="dadosAba.novaPesquisa && isAnaliseCredito">
    <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
    <div *ngIf="!isLoadingCadastrais">
      <h2 class="title-tab" *ngIf="documento.length == 11">{{infoCadastrais.nome}}</h2>
      <h2 class="title-tab" *ngIf="documento.length == 14">{{infoCadastrais.razaoSocial}}</h2>
      <p class="info-paragraph" *ngIf="documento.length == 11">CPF: <span>{{infoCadastrais.cpf}}</span></p>
      <p class="info-paragraph" *ngIf="documento.length == 14">CNPJ: <span>{{infoCadastrais.cnpj}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Nascimento: <span>{{infoCadastrais.dataNascimento |
            date: 'dd/MM/yyyy'}}</span></p>
      <p class="info-paragraph" *ngIf="documento.length == 14">CNAE: <span>{{infoCadastrais.cnaeFiscal}} -
          {{infoCadastrais.cnaeNome}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Filiação: <span>{{infoCadastrais.filiacao}}</span></p>
      <p class="info-paragraph" *ngIf="documento.length == 14">Capital Social:
        <span>{{infoCadastrais.capitalSocial}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Óbito: <span>{{infoCadastrais.obitoIndicador}}</span>
      </p>
      <p class="info-paragraph" *ngIf="documento.length == 14">Data de inicio das atividades:
        <span>{{infoCadastrais.dataInicioAtividades | date: 'dd/MM/yyyy'}}</span></p>

      <p class="info-paragraph" *ngIf="documento.length == 11">Situação: <span>{{infoCadastrais.situacaoCpf}}</span>
      </p>
      <p class="info-paragraph" *ngIf="documento.length == 14">Situação:
        <span>{{infoCadastrais.situacaoCadastral}}</span></p>

    </div>
  </div>
  <div class="col d-flex gaps">
    <div class="content-box">
      <div *ngIf="!dadosAba.novaPesquisa">
        <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length > 0">
          <p *ngFor="let telefone of infoCadastrais?.telefones">{{telefone}}</p>
        </div>
        <!-- <br> -->
        <p *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length == 0"> Não localizado</p>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && isAnaliseCredito">
        <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length > 0">
          <p *ngFor="let telefone of infoCadastrais?.telefone">{{telefone}}</p>
        </div>
        <!-- <br> -->
        <p *ngIf="!isLoadingCadastrais && infoCadastrais.telefones?.length == 0"> Não localizado</p>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && !isAnaliseCredito">
        <h4 class="d-flex align-items-center">Telefones &nbsp; <mat-icon> phone</mat-icon>
        </h4>
        <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingTelefones"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="infoCadastraisTelefone.tipoDocumento == 2  && !isLoadingTelefones">
          <p *ngFor="let telefone of infoCadastraisTelefone.dadosCnpj.telefones">{{telefone}}</p>
          <p *ngIf=" infoCadastraisTelefone.dadosCnpj?.telefones?.length == 0"> Não localizado</p>
        </div>

        <div class="mt-3 box-scroll" *ngIf="infoCadastraisTelefone.tipoDocumento == 1  && !isLoadingTelefones">
          <p *ngFor="let telefone of infoCadastraisTelefone.dadosCpf.telefones">{{telefone}}</p>
          <p *ngIf=" infoCadastraisTelefone.dadosCpf?.telefones?.length == 0"> Não localizado</p>
        </div>
        <!-- <br> -->
      </div>
    </div>


    <div class="content-box">
      <!-- ==== credito===  -->
      <div *ngIf="!dadosAba.novaPesquisa && isAnaliseCredito">
        <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cpf">
          <p *ngIf="infoCadastrais?.emails?.length > 0">
            <!-- <span>xaxaxa</span> -->
            <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
          </p>
          <p *ngIf="infoCadastrais?.emails?.length == 0">
            <span>Não Localizado</span>
          </p>
        </div>

        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cnpj">
          <p *ngIf="infoCadastrais?.emails?.length > 0">
            <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
          </p>
          <p *ngIf="infoCadastrais?.emails?.length == 0">
            <span>Não Localizado</span>
          </p>
        </div>
        <br>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && isAnaliseCredito">
        <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cpf">
          <p *ngIf="infoCadastrais?.emails?.length > 0">
            <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
          </p>
          <p *ngIf="infoCadastrais?.emails?.length == 0">
            <span>Não Localizado</span>
          </p>
        </div>

        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais.cnpj">
          <p *ngIf="infoCadastrais?.emails?.length > 0">
            <span *ngFor="let email of infoCadastrais?.emails">{{ email }}</span>
          </p>
          <p *ngIf="infoCadastrais?.emails?.length == 0">
            <span>Não Localizado</span>
          </p>
        </div>
        <br>
      </div>

      <!-- === diferente de credito ==== -->
      <div *ngIf="!dadosAba.novaPesquisa && !isAnaliseCredito">
        <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf">
          <p *ngFor="let email of infoCadastrais.emails">{{email ? email : 'Não Localizado'}}</p>
        </div>

        <div class="mt-3 box-scroll" *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj">
          <!-- <p>{{infoCadastrais.correioEletronico}}</p> -->
          <p *ngFor="let email of infoCadastrais.emails">{{email}}</p>
          <p *ngIf="infoCadastrais.emails?.length == 0">Não Localizado</p>
        </div>
        <br>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && !isAnaliseCredito">
        <h4 class="d-flex align-items-center">E-mails &nbsp; <mat-icon> mail_outline</mat-icon>
        </h4>
        <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingEmails"></loader-only>
        <div class="mt-3 box-scroll" *ngIf="infoCadastraisEmails.tipoDocumento == 2 && !isLoadingEmails">
          <!-- <p> {{infoCadastraisEmails.dadosCnpj.correioEletronico}}</p> -->
          <p *ngFor="let email of infoCadastraisEmails.dadosCnpj.emails">{{email}}</p>
          <p
            *ngIf="infoCadastraisEmails.dadosCnpj?.emails?.length == 0">
            Não localizado</p>
        </div>

        <div class="mt-3 box-scroll" *ngIf="infoCadastraisEmails.tipoDocumento == 1 && !isLoadingEmails">
          <p *ngFor="let email of infoCadastraisEmails.dadosCpf?.emails"> {{email}}</p>
          <p *ngIf="infoCadastraisEmails.dadosCpf?.emails?.length == 0"> Não localizado</p>
        </div>
        <br>
      </div>
    </div>

    <!-- ================= endereços========================= -->

    <div class="content-box">
      <div *ngIf="!dadosAba.novaPesquisa && !isAnaliseCredito">
        <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
          <!-- <p>{{infoCadastrais?.logradouro}} {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}}
            {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p> -->
          <div *ngFor="let endereco of infoCadastrais?.enderecos">
            <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
              {{endereco?.uf}}</p>
          </div>
          <p
            *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && infoCadastrais?.enderecos?.length == 0">
            Não localizado</p>
        </div>

        <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
          <div *ngFor="let endereco of infoCadastrais?.enderecos">
            <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
              {{endereco?.uf}}</p>
          </div>
          <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos?.length == 0">Não
            Localizado</p>
        </div>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && !isAnaliseCredito">
        <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
        </h4>
        <loader-only class="mt-3" class="mt-3" *ngIf="isLoadingEnderecos"></loader-only>
        <div class="box-scroll" *ngIf="infoCadastraisEnderecos.tipoDocumento == 2 && !isLoadingEnderecos">
          <!-- <p class="mt-3">{{infoCadastraisEnderecos.dadosCnpj?.logradouro}}
            {{infoCadastraisEnderecos.dadosCnpj?.numero}} {{infoCadastraisEnderecos.dadosCnpj?.complemento}}
            {{infoCadastraisEnderecos.dadosCnpj?.bairro}} - {{infoCadastraisEnderecos.dadosCnpj?.uf}}</p> -->
          <div *ngFor="let endereco of infoCadastraisEnderecos.dadosCnpj?.enderecos">
            <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
              {{endereco?.uf}}</p>
          </div>
          <p
            *ngIf="infoCadastraisEnderecos.dadosCnpj?.enderecos?.length == 0">
            Não localizado</p>
        </div>

        <div class="box-scroll" *ngIf="infoCadastraisEnderecos.tipoDocumento == 1 && !isLoadingEnderecos">
          <div *ngFor="let endereco of infoCadastraisEnderecos.dadosCpf?.enderecos">
            <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} {{endereco?.bairro}} -
              {{endereco?.uf}}</p>
          </div>
          <p *ngIf="!isLoadingCadastrais && infoCadastraisEnderecos.dadosCpf?.enderecos?.length == 0">Não Localizado</p>
        </div>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && isAnaliseCredito">
        <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
          <p>{{infoCadastrais?.logradouro}}, {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}}
            {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p>
          <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro"> Não localizado</p>
        </div>

        <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
          <div *ngFor="let endereco of infoCadastrais.enderecos">
            <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} , {{endereco?.bairro}} -
              {{endereco?.uf}}</p>
          </div>
          <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos?.length == 0">Não Localizado
          </p>
        </div>
      </div>

      <div *ngIf="!dadosAba.novaPesquisa && isAnaliseCredito">
        <h4 class="d-flex align-items-center">Endereços &nbsp; <mat-icon> place</mat-icon>
        </h4>
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <div class="box-scroll" *ngIf="infoCadastrais.cnpj && !isLoadingCadastrais">
          <p>{{infoCadastrais?.logradouro}}, {{infoCadastrais?.numero}} {{infoCadastrais?.complemento}}
            {{infoCadastrais?.bairro}} - {{infoCadastrais?.uf}} </p>
          <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cnpj && !infoCadastrais?.logradouro"> Não localizado</p>
        </div>

        <div class="box-scroll" *ngIf="infoCadastrais.cpf && !isLoadingCadastrais">
          <div *ngFor="let endereco of infoCadastrais.enderecos">
            <p>{{endereco?.logradouro}} {{endereco?.numero}} {{endereco?.complemento}} , {{endereco?.bairro}} -
              {{endereco?.uf}}</p>
          </div>
          <p *ngIf="!isLoadingCadastrais && infoCadastrais?.cpf && infoCadastrais?.enderecos?.length == 0">Não Localizado
          </p>
        </div>
      </div>
    </div>

    <!-- ============================================== -->

    <div class="content-box">
      <h4 class="d-flex align-items-center">Mapa &nbsp; <mat-icon> map</mat-icon>
      </h4>
      <div *ngIf="!dadosAba.novaPesquisa">
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <iframe *ngIf="!isLoadingCadastrais" id="localizacao" width="100%" frameborder="0" scrolling="no"
          marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
        <br>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && isAnaliseCredito">
        <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
        <iframe *ngIf="!isLoadingCadastrais" id="localizacao" width="100%" frameborder="0" scrolling="no"
          marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
        <br>
      </div>

      <div *ngIf="dadosAba.novaPesquisa && !isAnaliseCredito">
        <loader-only class="mt-3" *ngIf="isLoadingEnderecos"></loader-only>
        <iframe *ngIf="infoCadastraisEnderecos.tipoDocumento == 2 && !isLoadingEnderecos" id="localizacao" width="100%"
          frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>

        <iframe *ngIf="infoCadastraisEnderecos.tipoDocumento == 1 && !isLoadingEnderecos" id="localizacao" width="100%"
          frameborder="0" scrolling="no" marginheight="0" marginwidth="0" [src]="url" class="iframe-mapa"></iframe>
        <br>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="!dadosAba.novaPesquisa">
  <loader-only class="mt-3" *ngIf="isLoadingCadastrais"></loader-only>
  <app-dados-cadastrais-completos *ngIf="!isLoadingCadastrais" [objetoRelatorioCadastral]="infoCadastrais">
  </app-dados-cadastrais-completos>
</div>
<div *ngIf="dadosAba.novaPesquisa">
  <loader-only class="mt-3" *ngIf="isLoadingCadastraisGeral"></loader-only>
  <app-dados-cadastrais-completos *ngIf="!isLoadingCadastraisGeral" [objetoNovaPesquisa]="infoCadastraisGeral">
  </app-dados-cadastrais-completos>
</div> -->

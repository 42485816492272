import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { ModalDadosComponent } from './modal-dados/modal-dados.component';

@Component({
  selector: 'app-cruzamento-tela',
  templateUrl: './cruzamento-tela.component.html',
  styleUrls: ['./cruzamento-tela.component.scss']
})
export class CruzamentoTelaComponent implements OnInit {
  @Input() dadosAba: any; // Recebe os dados da aba
  telefones;
  emails;
  enderecos;
  processos
  idPesquisa;
  infoPesquisa
  isLoadingInfoPesquisa = false;
  isLoadingDados = false;

  dadosCruzados;


  panelOpenState = false;
  constructor(
    private pesquisaService: PesquisaService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    console.log(this.dadosAba)
    this.idPesquisa = this.dadosAba?.idpesquisa?.id
    
    setTimeout(() => {
      this.getInfoPesquisa()
      this.getDadosCruzados()
    }, 1000);
  }

  getDadosCruzados(){
    this.isLoadingDados = true;
    this.pesquisaService.getDadosCruzados(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.dadosCruzados =  this.transformarRetorno(data);
      this.isLoadingDados = false;
    })
  }

  transformarRetorno(dados: any): any[] {
    return Object.keys(dados).map(key => {
      return {
        nome: key, // Usa a chave como o nome da empresa
        ...dados[key] // Copia as outras propriedades (emails, telefones, etc.)
      };
    });
  }

  getInfoPesquisa() {
    this.isLoadingInfoPesquisa = true;
    this.pesquisaService.getInfoCruzamento(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.infoPesquisa = data;
      this.infoPesquisa = JSON.parse(this.infoPesquisa.origemCruzamentoDados);
      this.isLoadingInfoPesquisa = false
    })
  }

  openModalDados(dados, tipoPesquisa){
    this.dialog.open(ModalDadosComponent, {
      disableClose: true,
      width: '760px',
      maxHeight: '550px',
      data: {
        dados: dados,
        tipoPesquisa: tipoPesquisa
      }
    });
  }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dados-cadastrais-completos',
  templateUrl: './dados-cadastrais-completos.component.html',
  styleUrls: ['./dados-cadastrais-completos.component.scss']
})
export class DadosCadastraisCompletosComponent implements OnInit {

  panelOpenState = false;

  isCpf = false;
  isCnpj = false;
  @Input() objetoRelatorioCadastral: any;
  @Input() objetoNovaPesquisa: any

  isNovaPesquisa = false;
  pesquisaExistente = false;
  
  constructor() { }

  ngOnInit(): void {
    if (this.objetoNovaPesquisa && this.objetoNovaPesquisa.tipoDocumento) {
      this.isNovaPesquisa = true;
    
      if (this.objetoNovaPesquisa.tipoDocumento === 2) {
        this.isCnpj = true;
      }
      if (this.objetoNovaPesquisa.tipoDocumento === 1) {
        this.isCpf = true;
      }
    }
    
    if (this.objetoRelatorioCadastral && (this.objetoRelatorioCadastral.cnpj || this.objetoRelatorioCadastral.cpf)) {
      this.pesquisaExistente = true;
      if (this.objetoRelatorioCadastral.cnpj) {
        this.isCnpj = true;
      }
      if (this.objetoRelatorioCadastral.cpf) {
        this.isCpf = true;
      }
    }
  }
}
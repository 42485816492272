<div class="container">
  <div class="content-box">
    <h3 class="identificador-title text-center">Cruzamento de Dados</h3>
    <div class="row" *ngIf="dadosAba">
      <div class="col col-grey">
        <p><b>Identificação:</b> {{dadosAba?.idpesquisa?.identificacao}}</p>
      </div>

      <div class="col col-grey">
        <p><b>Emitido por:</b> {{dadosAba?.idpesquisa?.usuarioCadastro}}</p>
      </div>

      <div class="col col-grey">
        <p><b>Data:</b> {{dadosAba?.idpesquisa?.data | date: 'dd/MM/yyyy HH:mm' }}</p>
      </div>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel class="py-2 mt-3" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            TOTAL DE DOCUMENTOS LANÇADOS {{infoPesquisa?.length}}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <loader-only *ngIf="isLoadingInfoPesquisa"></loader-only>
      <table class="table" *ngIf="!isLoadingInfoPesquisa">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-only p-2">Relação de documentos</th>
          </tr>
        </thead>
        <tbody class="corpo-processo"> <!-- Aplicando a classe para rolagem -->
          <ng-container *ngFor="let info of infoPesquisa">
            <tr class="table-info text-center d-flex flex-column">
              <td>{{info?.Nome}} - {{info?.Documento}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="py-2 mt-3" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            DADOS DE PROCESSOS COMPARTILHADOS
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <loader-only *ngIf="isLoadingDados"></loader-only>
      <table class="table" *ngIf="!isLoadingDados && dadosCruzados?.length > 0">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-only p-2">
              Pessoas Relacionadas que compartilham os mesmos processos no mesmo polo
            </th>
          </tr>
        </thead>
        <tbody class="corpo-processo"> 
          <ng-container *ngFor="let info of dadosCruzados">
            <tr class="table-info text-center">
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span *ngIf="info?.documentos.length == 1">{{info?.documentos}}</span>
                  <span *ngIf="info?.documentos.length > 1">{{info?.documentos[1]}}</span>
                  <span class="plus-data" *ngIf="info?.documentos.length > 1" (click)="openModalDados(info, 5)">+</span>
                </div>
              </td>
              <td style="width: 30%; text-align: left;" [ngClass]="pesquisadoOrigem ? 'bolder': ''">{{info?.nome}}</td>
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span>Endereços {{info?.enderecos.length}}</span>
                  <span class="plus-data" *ngIf="info?.enderecos.length > 0" (click)="openModalDados(info, 1)">+</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span>Telefones {{info?.telefones.length}}</span>
                  <span class="plus-data" *ngIf="info?.telefones.length > 0" (click)="openModalDados(info, 3)">+</span>
                </div>
              </td>
              <td style="width: 10%;">
                <div class="cell-flex">
                  <span>Emails {{info?.emails.length}}</span>
                  <span class="plus-data" *ngIf="info?.emails.length > 0" (click)="openModalDados(info, 2)">+</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span>Processos {{info?.processos.processosAtivo.length + info?.processos.processosPassivo.length}}</span>
                  <span class="plus-data" *ngIf="info?.processos.processosAtivo.length > 0 || info?.processos.processosPassivo.length > 0" (click)="openModalDados(info, 4)">+</span>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      
      <div *ngIf="!isLoadingDados && dadosCruzados?.length === 0" class="text-center">
        <p>Nenhum resultado encontrado.</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
<div class="form-ext">
    <mat-card class="pesq-info">
      <mat-card-header class="title-pesq">
        <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
        <p>Insira os dados para o cruzamento</p>
      </mat-card-header>
      <form class="novapesq-form">
        <div class="container">
          <div class="row d-flex justify-content-center align-items-center">
              <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                  <div class="chip-container" style="max-height: 150px; overflow-y: auto;">
                    <mat-chip *ngFor="let item of docsAdded" (removed)="removeChipDoc(item)"
                      class="rounded-chip d-flex justify-content-center align-items-center">
                      {{ item }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </div>
                  <mat-label *ngIf="docsAdded.length === 0">Digite um ou mais documentos</mat-label>
                  <input 
                    #autoInput 
                    matInput 
                    type="text" 
                    [formControl]="docControl" 
                    mask="000.000.000-00 || 00.000.000/0000-00"
                    (keydown.enter)="onEnterDoc()" 
                    (blur)="onEnterDoc()"
                    (keydown.esc)="clearChips()"
                    (paste)="onPasteDoc($event)"
                    [ngClass]="docsAdded.length > 0 ? 'mt-3' : ''"
                    [readonly]="myControl.value"> <!-- Campo de documentos fica readonly se identificador estiver preenchido -->
                  <mat-hint class="mt-3">Pressione ESC para apagar todos documentos</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-sm-1">
                <p class="text-center">ou</p>
              </div>
              <div class="col">
                <mat-form-field appearance="outline" style="width: 100%;">
                  <mat-label>Selecione um caso para cruzar dados</mat-label>
                  <input matInput placeholder="Filtrar casos..." [matAutocomplete]="auto" [formControl]="myControl" autocomplete="off" 
                    [readonly]="docsAdded.length > 0"> <!-- Campo de identificador fica readonly se houver documentos -->
                  
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" name="identificador">
                    <mat-option  *ngFor="let option of filteredTags | async" [value]="option" (click)="atribuiIdentificador(option)">
                      {{ option.tag }}
                    </mat-option>
                  </mat-autocomplete>
                  
                </mat-form-field>
              </div>
            <div class="col-md col-mail d-flex align-items-center">
              <mat-slide-toggle [checked]="formPesquisa.segundoNivel"
                (change)="formPesquisa.segundoNivel = $event.source.checked" name="notificacao"> Incluir sócios e pessoas relacionadas
              </mat-slide-toggle>
              <!-- <app-pesquisa-em-massa [fonteGrupo]="formPesquisa"></app-pesquisa-em-massa> -->
              <button mat-flat-button
                (click)="confirmarPesquisa()"         
                (dblClick)="confirmarPesquisa()" class="gs-botaoAzulPadrao m-3">Cruzar Dados <mat-icon>search</mat-icon></button>
  
            </div>
          </div>
  
          
        </div>
      </form>
    </mat-card>
    <div class="separador"></div>
    <mat-tab-group [selectedIndex]="selectedIndex" class="mt-4"
      (selectedTabChange)="atualizarIndiceAbaSelecionada($event)">
      <mat-tab *ngFor="let aba of abas; let i = index" [label]="aba.titulo" class="mt-4">
        <ng-template mat-tab-label class="d-flex align-items-center">
          <div class="col">{{aba.titulo}}</div>
          <button *ngIf="i > 0" mat-icon-button aria-label="Fechar" (click)="fecharAba(i)">
            <mat-icon class="icon-close-tab">close</mat-icon>
          </button>
        </ng-template>
        <mat-card class="pesq-re" *ngIf="aba.titulo == 'Histórico'" style="min-height: 30vw;">
          <div class="header-pesq">
            <div class="title-pesq">
              <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
              <p>Cruzamento de Dados Realizados</p>
            </div>
            <div class="container">
              <form class="novapesq-form">
                <div class="container">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-sm">
                      <mat-form-field appearance="outline">
                        <mat-label>{{labelTag}}</mat-label>
                        <mat-select [(ngModel)]="formFiltro.tag" name="tag">
                          <mat-option value="">Todos</mat-option>
                          <mat-option *ngFor="let option of tagIds" [value]="option.tagId">{{option.tag}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
  
                    <div class="col-md col-mail">
                      <mat-form-field class="example-form-field" appearance="outline">
                        <mat-label>Periodo</mat-label>
                        <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker"
                          [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end">
                          <input matStartDate placeholder="DD/MM/AAAA" formControlName="start"
                            [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                          <input matEndDate placeholder="DD/MM/AAAA" formControlName="end"
                            [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                      </mat-form-field>
                      <button mat-flat-button (click)="pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Filtrar
                        <mat-icon>search</mat-icon></button>
                      <button mat-flat-button (click)="clearForm(); pesquisarClicked()"
                        class="gs-botaoAzulPadrao m-3">Limpar Filtros</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
  
          <div class="separador"></div>
          <div class="resultado d-flex justify-content-center"
            [ngClass]="pesquisasSelecionadas?.length > 0 ? '': ''">
            <div class="line-center"></div>
  
            <div class="pesq-table">
  
              <div class="outer-wrapper">
                <div style="text-align: center;" *ngIf="resultadoPesquisa?.length == 0">
                  Nenhuma pesquisa encontrada.
                </div>
                <div class="table-wrapper" *ngIf="resultadoPesquisa?.length != 0">
                  <table class="table-grid">
                    <thead class="thead-grid">
                      <!-- <th class="th-grid">
                        <mat-checkbox (change)="selecionarTodosItens($event)"></mat-checkbox>
                        Todas
                      </th> -->
                      <th class="th-grid">{{labelTag}}</th>
                      <th class="th-grid">Emissão</th>
                      <th class="th-grid">Data</th>
                      <th class="th-grid">Status</th>
                      <th class="th-grid">Ações</th>
                    </thead>
                    <tbody class="tbody-grid">
                      <tr *ngFor="let o of resultadoPesquisa.listCruzamentoDados" class="tr-grid">
                        <!-- <td class="td-grid">
                          <div class="border-column">
                            <mat-checkbox [checked]="isItemSelected(o.pesquisa)"
                              (change)="toggleItemSelection(o.pesquisa)"></mat-checkbox>
                          </div>
                        </td> -->
                        <td class="td-grid">
                          <div class="border-column" *ngIf="o.identificacao?.length > 0">
                            {{o.identificacao }}</div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{o.usuarioCadastro}}
                          </div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{ o.data | date: 'dd/MM/yyyy HH:mm' }}
                          </div>
                        </td>
                        <td class="td-grid">
                          <div class="border-column">
                            {{ getStatusText(o.status) }}
                          </div>
                        </td>
                        <td class="d-flex justify-content-center">
                          <ul class="ul-table">
                            <li class="li-table" *ngIf="o.status != StatusPesquisa.Cancelada"><button
                                [disabled]="o.status == StatusPesquisa.Cancelada"
                                (click)="adicionarAba(o)"><img
                                  class="icon-table" src="../../../assets/icones/ICONE-NOVO-VISUALIZAÇÃO.svg" width="20px"
                                  height="20px" title="Visualizar a pesquisa em tela"></button></li>
                            <!-- <li class="li-table"><button
                                [disabled]="o.status != StatusPesquisa.Finalizada && o.status != StatusPesquisa.FinalizadaParcial"
                                (click)="exportarClicked(o)"><img class="icon-table" width="20px" height="20px"
                                  src="../../../assets/icones/ICONE-NOVO-PDF.svg"
                                  title="Exportar a pesquisa em PDF"></button></li>
                            <li class="li-table"><button
                                [disabled]="o.status != StatusPesquisa.Finalizada && o.status != StatusPesquisa.FinalizadaParcial"
                                (click)="exportarExcelClicked(o)"><img class="icon-table" width="20px" height="20px"
                                  src="../../../assets/icones/ICONE-NOVO-EXCEL.svg"
                                  title="Exportar a pesquisa em Excel"></button></li>
                            <li class="li-table"><button
                                [disabled]="o.status != StatusPesquisa.Finalizada && o.status != StatusPesquisa.FinalizadaParcial"
                                (click)="exportarReciboClicked(o)"><img class="icon-table" width="20px" height="20px"
                                  src="../../../assets/icones/novo-icone-recibo.svg" title="Emitir o recibo"></button>
                            </li>
                            <li class="li-table" *ngIf="isSuzano"><button
                                [disabled]="o.status != StatusPesquisa.Finalizada && o.status != StatusPesquisa.FinalizadaParcial"
                                (click)="refazerPesquisaExistente(o)"><mat-icon style="color: grey;"  matTooltip="Refazer Pesquisa">cached</mat-icon></button>
                            </li> -->
                            <!-- <li class="li-table" [hidden]="!isSuzano"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada" (click)="exportarLGPD(o)"><img class="icon-table" width="20px" height="20px" src="../../../assets/icones/novo-icone-recibo.svg" title="Clique para fazer o download/visualizar do conteúdo extraído da fonte"></button></li> -->
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
  
        <mat-card class="pesq-re" *ngIf="aba.titulo != 'Histórico'">
          <app-cruzamento-tela [dadosAba]="aba"></app-cruzamento-tela>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
    
  </div>
  
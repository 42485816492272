import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData, DecimalPipe } from '@angular/common';
import { HeaderComponent } from './template/header/header.component';
import { FooterComponent } from './template/footer/footer.component';
import { ViewSearchComponent } from './view/view-search/view-search.component';
import { NavComponent } from './template/nav/nav.component';
import { ButtonsPesqInfoComponent } from './view/buttons-pesq-info/buttons-pesq-info.component';
import { ButtonsPesqRealComponent } from './view/buttons-pesq-real/buttons-pesq-real.component';
import { PopcreditoComponent } from './view/popcredito/popcredito.component';
import { ButtonsActionsComponent } from './view/buttons-actions/buttons-actions.component';
import { PopPesqRealizadaComponent } from './view/poppesqrealizada/poppesqrealizada.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { AuthModule } from '../@auth/auth.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { SidenavComponent } from './template/sidenav/sidenav.component';
import { TableResultComponent } from './view/table-result/table-result.component';
import { MensageResponseComponent } from './view/mensage-response/mensage-response.component';
import { MensageErrorResponseComponent } from './view/mensage-error-response/mensage-error-response.component';
import { PesquisaEmMassaComponent } from './view/pesquisa-em-massa/pesquisa-em-massa.component';
import { ModalAnexoComponent } from './view/pesquisa-em-massa/modal-anexo/modal-anexo.component';
import { NotificacoesComponent } from './view/notificacoes/notificacoes.component';
import { CadastrarNotificacaoComponent } from './view/notificacoes/cadastrar-notificacao/cadastrar-notificacao.component';
import { ModalTermosComponent } from './view/modal-termos/modal-termos.component';
import { JanelaSelecaoComponent } from './view/janela-selecao/janela-selecao.component';
import { PrecificadorModule } from '../views/precificador-credito/precificador-credto.module';
import { ModalConfirmacaoComponent } from './view/modal-confirmacao/modal-confirmacao.component';
import { ModalExportarPesquisaLoteComponent } from './view/modal-exportar-pesquisa-lote/modal-exportar-pesquisa-lote.component';
import { BannerAvisoComponent } from './view/banner-aviso/banner-aviso.component';
import { ModalEdicaoTagComponent } from './view/modal-edicao-tag/modal-edicao-tag.component';
import { LoaderOnlyComponent } from './view/loader-only/loader-only.component';
// import { ProcessosComponent } from './view/visao-tela/processos/processos.component';
import { VisaoTelaModule } from './view/visao-em-tela/visao-em-tela.module';
import { ProdutoPesquisaComponent } from './template/produto-pesquisa/produto-pesquisa.component';
import { DadosCadastraisComponent } from './view/visao-tela/dados-cadastrais/dados-cadastrais.component';
import { ModalValoresPesquisaComponent } from './template/produto-pesquisa/modal-valores-pesquisa/modal-valores-pesquisa.component';
import { ModalMenorIdadeComponent } from './template/produto-pesquisa/modal-menor-idade/modal-menor-idade.component';
import { DetechEmTelaComponent } from './view/visao-tela/detech-em-tela/detech-em-tela.component';
import { ViewsModule } from '../views/views.module';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { LoaderMiniComponent } from './view/loader-mini/loader-mini.component';
import { ModalPesquisaLoteComponent } from './template/produto-pesquisa/modal-pesquisa-lote/modal-pesquisa-lote.component';
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavComponent,
    ViewSearchComponent,
    ButtonsPesqInfoComponent,
    ButtonsPesqRealComponent,
    ButtonsActionsComponent,
    PopcreditoComponent,
    PopPesqRealizadaComponent,
    SidenavComponent,
    TableResultComponent,
    MensageResponseComponent,
    MensageErrorResponseComponent,
    PesquisaEmMassaComponent,
    ModalAnexoComponent,
    NotificacoesComponent,
    CadastrarNotificacaoComponent,
    ModalTermosComponent,
    JanelaSelecaoComponent,
    ModalConfirmacaoComponent,
    ModalExportarPesquisaLoteComponent,
    BannerAvisoComponent,
    ModalEdicaoTagComponent,
    // LoaderOnlyComponent,
    ProdutoPesquisaComponent,
   
    DadosCadastraisComponent,
        ModalValoresPesquisaComponent,
        ModalMenorIdadeComponent,
    DetechEmTelaComponent,
    LoaderMiniComponent,
    ModalPesquisaLoteComponent,
  ],
  entryComponents: [PopPesqRealizadaComponent, LoaderOnlyComponent],
  imports: [
    SharedModule,
    PrecificadorModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    VisaoTelaModule,
    AppRoutingModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    AuthModule.forRoot(),
    RouterModule.forRoot([])
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    NavComponent,
    SidenavComponent,
    ViewSearchComponent,
    ButtonsPesqInfoComponent,
    ButtonsPesqRealComponent,
    ButtonsActionsComponent,
    PopcreditoComponent,
    PopPesqRealizadaComponent,
    TableResultComponent,
    MensageResponseComponent,
    PesquisaEmMassaComponent,
    JanelaSelecaoComponent,
    BannerAvisoComponent,
    LoaderOnlyComponent,
    ProdutoPesquisaComponent,
    DadosCadastraisComponent,
    DetechEmTelaComponent,
    LoaderMiniComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' },DecimalPipe],
})
export class ComponentsModule { }

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { ModalDadosComponent } from './modal-dados/modal-dados.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-cruzamento-tela',
  templateUrl: './cruzamento-tela.component.html',
  styleUrls: ['./cruzamento-tela.component.scss']
})
export class CruzamentoTelaComponent implements OnInit {
  @Input() dadosAba: any; // Recebe os dados da aba
  telefones: any;
  emails: any;
  enderecos: any;
  processos: any;
  idPesquisa: any;
  infoPesquisa: any;
  isLoadingInfoPesquisa = false;
  isLoadingDados = false;
  loadingOrigem = false;

  dadosCruzados: any[] = [];
  processosListagem: any;
  emailsListagem: any;
  telefonesListagem: any;
  enderecosListagem: any;
  nomesListagem:any;
  reverterFiltro;
  segundoNivel;

  // Variáveis para paginação
  dadosPaginados: any[] = [];
  pageIndex = 1; 
  pageSize = 10; 
  totalItems = 0;
  pageIndexOrigem: number = 1; // Índice da página para dados de origem
pageSizeOrigem: number = 40; // Tamanho da página
totalItemsOrigem: number = 0; // Total de itens retornados pela API

  selectedEmails = []
  selectedEnderecos = []
  selectedTelefones = []
  selectedProcessos = []
  selectedNomes = []
  exibeSegNivel = false;


  // Variáveis de filtro
  emailSearch: string = '';
  enderecoSearch: string = '';
  telefoneSearch: string = '';
  processoSearch: string = '';
  nomeSearch: string = '';

  // Listas filtradas
  filteredEmails: string[] = [];
  filteredEnderecos: string[] = [];
  filteredTelefones: string[] = [];
  filteredProcessos: string[] = [];
  filteredNomes: string[] = [];

  dadosOrigemPesquisa;
  panelOpenState = false;

  allSelectedEmails = false;
  allSelectedEnderecos = false;
  allSelectedTelefones = false;
  allSelectedProcessos = false;
  allSelectedNomes = false;

  isSelectAllChecked = false;

  constructor(
    private pesquisaService: PesquisaService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.pageSize = 40; // Define o tamanho fixo da página como 30
    this.pageIndex = 0; // Começa na primeira página
  
    setTimeout(() => {
      this.getNomes();
      this.getProcessos();
      this.getEnderecos();
      this.getTelefones();
      this.getemails();
      this.getDadosCruzados(this.pageIndex); // Carrega os dados cruzados
      this.getDadosCruzadosOrigem(this.pageSize, this.pageIndex)
      this.getFiltrosPreenchidos()
      this.filteredEmails = [...this.emailsListagem];
      this.filteredEnderecos = [...this.enderecosListagem];
      this.filteredTelefones = [...this.telefonesListagem];
      this.filteredProcessos = [...this.processosListagem];
      this.filteredNomes = [...this.nomesListagem];
    }, 1000);
  }
  
  getDadosCruzados(pageIndex: number) {
    const offset = pageIndex; // O offset deve ser igual ao índice da página
    this.isLoadingDados = true;
  
    this.pesquisaService.getDadosCruzados(this.dadosAba?.idpesquisa?.id, this.pageSize, offset).subscribe(data => {
      this.dadosCruzados = this.transformarRetorno(data.dados);
      this.totalItems = data.totalItems; // Certifique-se que a API retorna o total de itens
      this.isLoadingDados = false;
      this.updatePaginatedData(); // Atualiza os dados paginados
    });
  }

  getDadosCruzadosOrigem(limit: number, pageIndex: number) {
    const offset = pageIndex; // Cálculo correto do offset, mudando de 1 em 1
    this.loadingOrigem = true;
  
    this.pesquisaService.getDadosCruzadosOrigem(this.dadosAba?.idpesquisa?.id, limit, offset).subscribe(data => {
      this.dadosOrigemPesquisa = this.transformarRetorno(data.dados);
      this.totalItemsOrigem = data.totalItems; // Certifique-se que a API retorna o total de itens
      this.loadingOrigem = false;
    });
  }
  
  changePageOrigem(event: PageEvent) {
    this.pageIndexOrigem = event.pageIndex; // Atualiza o índice da página
    this.getDadosCruzadosOrigem(this.pageSize, this.pageIndexOrigem); // Chama os dados da nova página
  }

  changePage(event: PageEvent) {
    console.log('Mudança de página:', event);
    this.pageIndex = event.pageIndex; // Atualiza o índice da página
    this.getDadosCruzados(this.pageIndex); // Chama os dados da nova página
  }
  
  // Atualização de dados paginados
  updatePaginatedData() {
    if (this.dadosCruzados && this.dadosCruzados.length) {
      this.dadosPaginados = this.dadosCruzados.slice(0, this.pageSize);
    } else {
      this.dadosPaginados = []; // Caso não haja dados
    }
  }

  transformarRetorno(dados: any): any[] {
    console.log(dados.dados)
    return Object.keys(dados).map(key => {
      return {
        nome: key, // Usa a chave como o nome da empresa
        ...dados[key] // Copia as outras propriedades (emails, telefones, etc.)
      };
    });
  }

  getInfoPesquisa() {
    this.isLoadingInfoPesquisa = true;
    this.pesquisaService.getInfoCruzamento(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.infoPesquisa = data;
      this.infoPesquisa = JSON.parse(this.infoPesquisa.origemCruzamentoDados);
      this.isLoadingInfoPesquisa = false;
    });
  }

  openModalDados(dados, tipoPesquisa) {
    this.dialog.open(ModalDadosComponent, {
      disableClose: true,
      width: '80%',
      maxHeight: '550px',
      data: {
        dados: dados,
        tipoPesquisa: tipoPesquisa
      }
    });
  }

  getTelefones() {
    this.pesquisaService.getTelefonesListagem(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.telefonesListagem = data;
      this.selectedTelefones = this.telefonesListagem;
    });
  }

  getemails() {
    this.pesquisaService.getEmailsListagem(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.emailsListagem = data;
      this.selectedEmails = this.emailsListagem;
    });
  }

  getEnderecos() {
    this.pesquisaService.getEnderecosListagem(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.enderecosListagem = data;
      this.selectedEnderecos = this.enderecosListagem;
    });
  }

  getProcessos() {
    this.pesquisaService.getProcessosListagem(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.processosListagem = data;
      this.selectedProcessos = this.processosListagem
    });
  }

  getNomes() {
    this.pesquisaService.getNomesListagem(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      this.nomesListagem = data;
      this.selectedNomes = this.nomesListagem
    });
  }

  filtrar() {
    // Cria um array de filtros, incluindo apenas os valores selecionados
    const filtros: any[] = [];
    const filtroNomes: any[] = [];
  
    if (this.selectedEmails?.length) {
      filtros.push(...this.selectedEmails);
    }
    if (this.selectedTelefones?.length) {
      filtros.push(...this.selectedTelefones);
    }
    if (this.selectedEnderecos?.length) {
      filtros.push(...this.selectedEnderecos);
    }
    if (this.selectedProcessos?.length) {
      filtros.push(...this.selectedProcessos);
    }
    if (this.selectedNomes?.length) {
      filtroNomes.push(...this.selectedNomes);
    }
  
    const objFiltros = {
      id: this.dadosAba?.idpesquisa?.id,
      filtros: filtros,
      filtrarNomes:filtroNomes,
      removerDados: false,
      segundoNivel: this.segundoNivel
    };

    this.pesquisaService.cadastrarFiltros(objFiltros).subscribe(data => {
        setTimeout(() => {
          this.getDadosCruzados(0);
        }, 100);
    });
  }


  limparFiltros() {
    this.selectedEmails = this.emailsListagem;
    this.selectedEnderecos = this.enderecosListagem;
    this.selectedTelefones = this.telefonesListagem;
    this.selectedProcessos = this.processosListagem;
    this.selectedNomes = this.nomesListagem;
  }

  getTotalTelefones(): number {
    return this.dadosOrigemPesquisa.reduce((total, item) => total + item.telefones.length, 0);
  }

  getTotalEmails(): number {
    return this.dadosOrigemPesquisa.reduce((total, item) => total + item.emails.length, 0);
  }
  
  getTotalEnderecos(): number {
    return this.dadosOrigemPesquisa.reduce((total, item) => total + item.enderecos.length, 0);
  }

  getTotalProcessosAtivos(): number {
    return this.dadosOrigemPesquisa.reduce((total, item) => total + item.processos.processosAtivo.length, 0);
  }

  getTotalProcessosPassivos(): number {
    return this.dadosOrigemPesquisa.reduce((total, item) => total + item.processos.processosPassivo.length, 0);
  }

  getFiltrosPreenchidos() {
    this.pesquisaService.getFiltrosPreenchidos(this.dadosAba?.idpesquisa?.id).subscribe(data => {
      
      this.exibeSegNivel = data.segundoNivel;
      this.segundoNivel = data.segundoNivel
      // Verifica se 'filtrosAplicadosEmails' existe e não está vazio antes de filtrar
      this.selectedEmails = this.emailsListagem.filter(email =>
        data.filtrosAplicadosEmails.length > 0 ? !data.filtrosAplicadosEmails.includes(email) : true
      );
  
      // Verifica se 'filtrosAplicadosEnderecos' existe e não está vazio antes de filtrar
      this.selectedEnderecos = this.enderecosListagem.filter(endereco => 
        data.filtrosAplicadosEnderecos.length > 0 ? !data.filtrosAplicadosEnderecos.includes(endereco) : true
      );
  
      // Verifica se 'filtrosAplicadosProcessos' existe e não está vazio antes de filtrar
      this.selectedProcessos = this.processosListagem.filter(processo => 
        data.filtrosAplicadosProcessos.length > 0 ? !data.filtrosAplicadosProcessos.includes(processo) : true
      );
  
      // Verifica se 'filtrosAplicadosTelefones' existe e não está vazio antes de filtrar
      this.selectedTelefones = this.telefonesListagem.filter(telefone => 
        data.filtrosAplicadosTelefones.length > 0 ? !data.filtrosAplicadosTelefones.includes(telefone) : true
      );
  
      // Verifica se 'filtrosAplicadosNomes' existe e não está vazio antes de filtrar
      this.selectedNomes = this.nomesListagem.filter(nome => 
        data.filtrosAplicadosNomes.length > 0 ? !data.filtrosAplicadosNomes.includes(nome) : true
      );
    });
  }

  isAllSelected(field: string): boolean {
    switch (field) {
        case 'emails':
            return this.selectedEmails.length === this.emailsListagem.length;
        case 'enderecos':
            return this.selectedEnderecos.length === this.enderecosListagem.length;
        case 'telefones':
            return this.selectedTelefones.length === this.telefonesListagem.length;
        case 'processos':
            return this.selectedProcessos.length === this.processosListagem.length;
        case 'nomes':
            return this.selectedNomes.length === this.nomesListagem.length;
        default:
            return false;
    }
}

// Função para selecionar ou desmarcar todos
toggleSelectAll(field: string, event: MouseEvent) {
    event.stopPropagation(); // Impede a propagação do clique
    switch (field) {
        case 'emails':
            this.selectedEmails = this.isAllSelected('emails') ? [] : [...this.emailsListagem];
            break;
        case 'enderecos':
            this.selectedEnderecos = this.isAllSelected('enderecos') ? [] : [...this.enderecosListagem];
            break;
        case 'telefones':
            this.selectedTelefones = this.isAllSelected('telefones') ? [] : [...this.telefonesListagem];
            break;
        case 'processos':
            this.selectedProcessos = this.isAllSelected('processos') ? [] : [...this.processosListagem];
            break;
        case 'nomes':
            this.selectedNomes = this.isAllSelected('nomes') ? [] : [...this.nomesListagem];
            break;
    }
    console.log(this.selectedEmails)
}
}
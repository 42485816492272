<!-- <aba-dados-cadastrais (arrayChange)="handleArrayChange($event)" [dadosAba]="dadosAba"></aba-dados-cadastrais>

<app-sintese-patrimonial *ngIf="isPatrimonial" [idPesquisa]="idPesquisa" [aba]="aba" [statusPesquisa]="statusPesquisa"></app-sintese-patrimonial>

<app-sintese-processo [dadosAba]="dadosAba" *ngIf="isProcessos || isDossie"></app-sintese-processo>

<sintese-credito *ngIf="(isAnaliseCredito || isDossie) && exibeAnaliseCredito" [aba]="aba" class="mt-4"
  (exibeAnaliseCreditoChange)="atualizarExibeAnaliseCredito($event)"></sintese-credito>

<div *ngIf="!dadosAba.novaPesquisa">
  <loader-only class="mt-3" *ngIf="isLoadingCadastraisCompletos"></loader-only>
  <app-dados-cadastrais-completos *ngIf="!isLoadingCadastraisCompletos" [objetoRelatorioCadastral]="infoCadastrais">
  </app-dados-cadastrais-completos>
</div>
<div *ngIf="dadosAba.novaPesquisa">
  <loader-only class="mt-3" *ngIf="isLoadingCadastraisCompletos"></loader-only>
  <app-dados-cadastrais-completos *ngIf="!isLoadingCadastraisCompletos" [objetoNovaPesquisa]="infoCadastraisGeral">
  </app-dados-cadastrais-completos>
</div>

<app-sintese-patrimonial *ngIf="isDossie" [idPesquisa]="idPesquisa" [aba]="aba" [statusPesquisa]="statusPesquisa"></app-sintese-patrimonial>

<midias-negativas [dadosPesquisa]="idPesquisa"></midias-negativas>

<app-detech-em-tela [doc]="documento" [dataNasc]="aba.dataNasc"></app-detech-em-tela>

<app-analise-credito-completo *ngIf="(isAnaliseCredito || isDossie) && exibeAnaliseCredito && idPesquisa" [aba]="aba"
  class="mt-4" (exibeAnaliseCreditoChange)="atualizarExibeAnaliseCredito($event)" [idPesquisa]="idPesquisa">
</app-analise-credito-completo>

<div role="button" class="d-flex justify-content-center  align-items-center border-grey-more mt-4 baixar-pdf"
  *ngIf="!isLoadingPdf" [style.background]="getCorPorcentagem(porcentagemPesquisa)" (click)="exportarClicked()">
  <p>Informações completas estão disponíveis em PDF</p>
  <mat-icon class="pdf-icon">save_alt</mat-icon>
</div>

<div class="d-flex justify-content-center  align-items-center border-grey-more mt-4 baixar-pdf" *ngIf="isLoadingPdf"
  [style.background]="getCorPorcentagem(porcentagemPesquisa)">
  <p>Informações completas estão disponíveis em PDF</p>
  <div class="d-flex align-items-center">
    <div class="loading">
    </div>
    <span class="percentual-pesquisa"
      *ngIf="porcentagemPesquisa < 100 && statusPesquisa < 4">{{porcentagemPesquisa}}%</span>
  </div>
</div> -->

<div *ngFor="let ordem of ordemComponentes">
  <ng-container *ngIf="ordem === 1">
    <aba-dados-cadastrais (arrayChange)="handleArrayChange($event)" [dadosAba]="dadosAba"></aba-dados-cadastrais>
  </ng-container>

  <ng-container *ngIf="ordem === 2">
    <div *ngIf="!dadosAba.novaPesquisa">
      <loader-only class="mt-3" *ngIf="isLoadingCadastraisCompletos"></loader-only>
      <app-dados-cadastrais-completos *ngIf="!isLoadingCadastraisCompletos" [objetoRelatorioCadastral]="infoCadastrais">
      </app-dados-cadastrais-completos>
    </div>
    <div *ngIf="dadosAba.novaPesquisa">
      <loader-only class="mt-3" *ngIf="isLoadingCadastraisCompletos"></loader-only>
      <app-dados-cadastrais-completos *ngIf="!isLoadingCadastraisCompletos" [objetoNovaPesquisa]="infoCadastraisGeral">
      </app-dados-cadastrais-completos>
    </div>
  </ng-container>

  <ng-container *ngIf="ordem === 3">
    <app-sintese-processo [dadosAba]="dadosAba" *ngIf="isProcessos || isDossie"></app-sintese-processo>
  </ng-container>

  <ng-container *ngIf="ordem === 4">
    <app-sintese-patrimonial *ngIf="isPatrimonial || isDossie" [idPesquisa]="idPesquisa" [aba]="aba" [statusPesquisa]="statusPesquisa"></app-sintese-patrimonial>
  </ng-container>

  <ng-container *ngIf="ordem === 5">
    <sintese-credito *ngIf="(isAnaliseCredito || isDossie) && exibeAnaliseCredito" [aba]="aba" class="mt-4" (exibeAnaliseCreditoChange)="atualizarExibeAnaliseCredito($event)"></sintese-credito>
  </ng-container>

  <ng-container *ngIf="ordem === 6">
    <app-analise-credito-completo *ngIf="(isAnaliseCredito || isDossie) && exibeAnaliseCredito && idPesquisa" [aba]="aba" class="mt-4" (exibeAnaliseCreditoChange)="atualizarExibeAnaliseCredito($event)" [idPesquisa]="idPesquisa"></app-analise-credito-completo>
  </ng-container>

  <ng-container *ngIf="ordem === 7">
    <midias-negativas [dadosPesquisa]="idPesquisa"></midias-negativas>
  </ng-container>

  <ng-container *ngIf="ordem === 8">
    <app-detech-em-tela [doc]="documento" [dataNasc]="aba.dataNasc"></app-detech-em-tela>
  </ng-container>
</div>


<div role="button" class="d-flex justify-content-center  align-items-center border-grey-more mt-4 baixar-pdf"
  *ngIf="!isLoadingPdf" [style.background]="getCorPorcentagem(porcentagemPesquisa)" (click)="exportarClicked()">
  <p>Informações completas estão disponíveis em PDF</p>
  <mat-icon class="pdf-icon">save_alt</mat-icon>
</div>
<div class="d-flex justify-content-center  align-items-center border-grey-more mt-4 baixar-pdf" *ngIf="isLoadingPdf"
  [style.background]="getCorPorcentagem(porcentagemPesquisa)">
  <p>Informações completas estão disponíveis em PDF</p>
  <div class="d-flex align-items-center">
    <div class="loading">
    </div>
    <span class="percentual-pesquisa"
      *ngIf="porcentagemPesquisa < 100 && statusPesquisa < 4">{{porcentagemPesquisa}}%</span>
  </div>
</div>


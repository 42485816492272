import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, from, map, mergeMap, Observable, of, startWith } from 'rxjs';
import { ModalValoresPesquisaComponent } from 'src/app/components/template/produto-pesquisa/modal-valores-pesquisa/modal-valores-pesquisa.component';
import { ModalAnexoComponent } from 'src/app/components/view/pesquisa-em-massa/modal-anexo/modal-anexo.component';
import { ProdutosEnum } from 'src/app/shared/enums/produtos.enum';
import { AuthService } from 'src/app/shared/services/http/auth.service';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-modal-validacao-cruzamento',
  templateUrl: './modal-validacao-cruzamento.component.html',
  styleUrls: ['./modal-validacao-cruzamento.component.scss']
})
export class ModalValidacaoCruzamentoComponent implements OnInit {

  anexandoArquivo = false
  produtoId;
  totalDocumentos;
  produtoReferencia;
  inputManual;
  excelBase64;
  tipoPesquisaLote;
  dataTable = [];
  payloadRequest = {};
  docsAdded;

  somaValores

  progresso: number = 0; // Progresso inicializado em 0%
  totalDocumentosContador: number = 0; // Inicializa como 0
  documentosProcessadosComSucessoContador: number = 0; // Inicializa como 0

  documentosProcessados;
  documentosProcessadosComSucesso
  objetoRequest;
  isLoading: boolean = false;
  myControl = new FormControl();
  tagIds = [];
  addedItems = [];
  usuario;
  autoCompletePanel: any;
  labelTag: String = 'Tag';
  filteredTags: Observable<any[]>;

  constructor(
    private router: Router,
    private ui: UiService,
    private pesquisaService: PesquisaService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalAnexoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getProductParamter();
    console.log(this.data);
    this.processarDocumentos(this.data.docs);
    this.getUsuario();
    this.getChips()

    this.filteredTags = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTags(value))
    );

  }

  getProductParamter() {
    this.produtoId = ProdutosEnum.Cadastrais;
    this.produtoReferencia = "cadastrais";
  }


  processarDocumentos(docs: string[]): void {
    this.documentosProcessados = [];
  
    if (docs.length === 0 && this.data.objPesquisa.identificador) {
      this.processarPorIdentificador(this.data.objPesquisa.identificador.tagId);
    } else {
      this.processarPorDocumentos(docs);
    }
  }
  
  private processarPorIdentificador(identificador: string): void {
    this.pesquisaService.getCruzamentoRealizado({ tag: identificador }, 'cadastrais').pipe(
      map((response: any) => {
        console.log(response.list)
        this.processarDocumento(response.list);
      }),
      catchError((err) => {
        console.error('Erro ao processar documento:', err);
        this.documentosProcessados.push({
          nome: null,
          dataCadastro: '-',
          status: 'Não foi encontrado nenhuma pesquisa para esse documento',
        });
        return of(null);
      })
    ).subscribe(
      () => { },
      (err) => {
        console.error('Erro global inesperado:', err);
      },
      () => {
        console.log('Processamento concluído para identificador');
      }
    );
  }
  
  private processarPorDocumentos(docs: string[]): void {
    from(docs).pipe(
      mergeMap((numero) => {
        const documentoLimpo = numero.replace(/\D/g, '');

        // Chamada à API com o documento
        return this.pesquisaService.getPesquisaRealizada({ numero: documentoLimpo }, 'cadastrais').pipe(
          map((response: any) => {
            const { pesquisa } = response.list;
            const documentoProcessado = {
              id: pesquisa.id,
              numero: pesquisa.numero,
              nome: pesquisa.nome,
              dataCadastro: pesquisa.dataCadastro,
              status: pesquisa.status
            };
            this.documentosProcessados.push(documentoProcessado);
            this.documentosProcessadosComSucesso = this.documentosProcessados
              .filter(doc => doc.status === 4 || doc.status === 7)
              .map(doc => ({
                documento: doc.numero,
                pesquisaID: doc.id,
                nome: doc.nome
              }));
            console.log(this.documentosProcessadosComSucesso);
          }),
          catchError((err) => {
            console.error('Erro ao processar documento:', err);
            this.documentosProcessados.push({
              numero: documentoLimpo,
              nome: null,
              dataCadastro: '-',
              status: 'Não foi encontrado nenhuma pesquisa para esse documento',
            });
            return of(null);
          })
        );
      }, 5)
    ).subscribe(
      () => { },
      (err) => {
        console.error('Erro global inesperado:', err);
      },
      () => {
        console.log('Processamento concluído para documentos');
      }
    );
  }
  
  private processarDocumento (pesquisas: any[]): void {
    pesquisas.forEach(pesquisa => {
      const documentoProcessado = {
        id: pesquisa.pesquisa.id,
        numero: pesquisa.pesquisa.numero,
        nome: pesquisa.pesquisa.nome,
        dataCadastro: pesquisa.pesquisa.dataCadastro,
        status: pesquisa.pesquisa.status
      };
      this.documentosProcessados.push(documentoProcessado);
      console.log(this.documentosProcessados)
    });
  
    this.documentosProcessadosComSucesso = this.documentosProcessados
      .filter(doc => doc.status === 4 || doc.status === 7)
      .map(doc => ({
        documento: doc.numero,
        pesquisaID: doc.id,
        nome: doc.nome
      }));
      
    console.log(this.documentosProcessadosComSucesso);
  }
  
  
  


  atualizarProgresso() {
    if (this.totalDocumentosContador > 0) {
      this.progresso = Math.round((this.documentosProcessadosComSucessoContador / this.totalDocumentosContador) * 100);
      this.cdr.detectChanges(); // Detecta mudanças na visão
    }
  }

  enviarPesquisaParaProcessamento() {
    this.objetoRequest = {
      identificacao: this.data.objPesquisa.identificador.tag,
      origemDados: this.documentosProcessadosComSucesso,
      tags: this.addedItems
    }

    const processingSnackBar = this.snackBar.open('Processando documentos, por favor, aguarde...', '', {
      duration: undefined,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['processing-snackbar']
    });
    this.dialogRef.close();
    this.pesquisaService.cadastrarCruzamentoDados(this.objetoRequest).subscribe(
      data => {
        if (HttpStatusCode.Ok) {
          this.ui.loaded().subscribe(() => {
            processingSnackBar.dismiss();

            this.snackBar.open('Documentos processados com sucesso. Consulte o histórico!', '', {
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: ['success-snackbar']
            });
            this.dialogRef.close();
            // Recarrega a página
            window.location.reload();
          });
        }
      },
      (err: any) => {
        this.ui.loaded().subscribe(() => {
          processingSnackBar.dismiss();

          this.snackBar.open('Erro ao processar documentos: ' + err.error, '', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['error-snackbar']
          });

          this.ui.info(err.error);
        });
      }
    );
  }

  getUsuario() {
    this.authService.callbackUsuario().subscribe(usuario => {
      if (!usuario) {
        return;
      }
      if (usuario) {
        this.usuario = usuario;
        if (usuario.cliente.descricaoTag && usuario.cliente.descricaoTag.trim().length > 0) {
          this.labelTag = usuario.cliente.descricaoTag;
        }
      }
    })
  }

  addChip(value) {
    this.myControl.reset();
    if (!this.addedItems.find(item => item.tag === value.tag)) {
      this.addedItems.push(value);
    }
    this.tagIds = this.tagIds.filter(item => item.tag !== value.tag);
    this.filteredTags = this.filteredTags.pipe(
      map(tags => tags.filter(tag => tag.tagId !== value.tagId))
    );
    this.myControl.reset();
  }

  removeChip(item): void {
    const index = this.addedItems.indexOf(item);
    if (index !== -1) {
      this.addedItems.splice(index, 1);
      this.tagIds.push(item);  // Adiciona o item de volta ao array tagIds
      this.filteredTags = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTags(value))
      );
    }
  }

  onInputBlur(): void {
    const value = this.myControl.value.trim();
    const valueAdded = this.addedItems.map(x => x.tag)
    const valueGetted = this.tagIds.map(x => x.tag)
    if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
      this.enviarChips(value)
    }

    this.myControl.reset();
  }

  enviarChips(value) {
    this.ui.loading();
    const obj = {
      clienteId: this.usuario.cliente.id,
      tags: [{
        tagId: 0,
        tag: value
      }]
    }
    this.pesquisaService.cadastrarTagId(obj).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.addedItems.push(response[0])
        this.filteredTags = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterTags(value))
        );
        this.myControl.reset();
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toast.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  onEnter(event: KeyboardEvent): void {
    if (document.activeElement === event.target) {
      event.preventDefault();

      const value = this.myControl.value.trim();
      const valueAdded = this.addedItems.map(x => x.tag)
      const valueGetted = this.tagIds.map(x => x.tag)
      if (value !== '' && !valueAdded.includes(value) && valueGetted.includes(value)) {
        let tagEnter = this.tagIds.filter(x => x.tag == value);
        this.addChip(tagEnter[0])
      }
      if (value !== '' && !valueAdded.includes(value) && !valueGetted.includes(value)) {
        this.enviarChips(value)
      }

      this.myControl.reset();
    }
  }

  getChips() {
    this.ui.loading()
    this.pesquisaService.getTagID(this.usuario.cliente.id).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.tagIds = response
      })
    }, (err: any) => {
      this.ui.loaded().subscribe(() => {
        this.toast.error('Ocorreu um erro, tente novamente.')
      })
    });
  }

  private _filterTags(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.tagIds.filter(tag => tag.tag.toLowerCase().includes(filterValue));
  }
}


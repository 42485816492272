
<div class="d-flex justify-content-between">
  <h3>{{data?.dados?.nome}} QUE COMPARTILHAM
    <span *ngIf="data.tipoPesquisa == 4">PROCESSOS</span>
    <span *ngIf="data.tipoPesquisa == 2">EMAILS</span>
    <span *ngIf="data.tipoPesquisa == 1">ENDEREÇOS</span>
    <span *ngIf="data.tipoPesquisa == 3">TELEFONES</span>
    <span *ngIf="data.tipoPesquisa == 5">DOCUMENTOS</span>
    COM OS PESQUISADOS
  </h3>
    <mat-icon mat-dialog-close>close</mat-icon>
</div>
<!-- Tabela de Endereços -->
<table class="table" *ngIf="data.tipoPesquisa == 1 && data.dados.enderecos?.length > 0">
  <thead class="thead-light">
    <tr class="text-center">
      <th scope="col" class="thead-top-only p-2">Endereços</th>
    </tr>
  </thead>
  <tbody class="corpo-processo">
    <ng-container>
      <tr class="table-info text-center d-flex flex-column" *ngFor="let endereco of data.dados.enderecos"> 
        <td>{{ endereco }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div *ngIf="data.tipoPesquisa == 1 && data.dados.enderecos?.length === 0" class="text-center">
  <p>Nenhum endereço encontrado.</p>
</div>

<!-- Tabela de Emails -->
<table class="table" *ngIf="data.tipoPesquisa == 2 && data.dados.emails?.length > 0">
  <thead class="thead-light">
    <tr class="text-center">
      <th scope="col" class="thead-top-only p-2">Emails</th>
    </tr>
  </thead>
  <tbody class="corpo-processo">
    <ng-container>
      <tr class="table-info text-center d-flex flex-column" *ngFor="let email of data.dados.emails"> 
        <td>{{ email }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div *ngIf="data.tipoPesquisa == 2 && data.dados.emails?.length === 0" class="text-center">
  <p>Nenhum email encontrado.</p>
</div>

<!-- Tabela de Telefones -->
<table class="table" *ngIf="data.tipoPesquisa == 3 && data.dados.telefones?.length > 0">
  <thead class="thead-light">
    <tr class="text-center">
      <th scope="col" class="thead-top-only p-2">Telefones</th>
    </tr>
  </thead>
  <tbody class="corpo-processo">
    <ng-container>
      <tr class="table-info text-center d-flex flex-column" *ngFor="let telefone of data.dados.telefones"> 
        <td>{{ telefone }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div *ngIf="data.tipoPesquisa == 3 && data.dados.telefones?.length === 0" class="text-center">
  <p>Nenhum telefone encontrado.</p>
</div>

<!-- Tabela de Processos Ativos -->
<div class="table-container mb-3">
  <table class="table tabela-tres-colunas" *ngIf="data.tipoPesquisa == 4 && processosAtivosEmGrupos?.length > 0">
    <thead class="thead-light">
      <tr class="text-center">
        <th scope="col" class="thead-top-only p-2" colspan="3">Processos Ativos</th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr class="table-info text-center" *ngFor="let grupo of processosAtivosEmGrupos">
          <td *ngFor="let processoAtivo of grupo">{{ processoAtivo }}</td>
          <td *ngFor="let i of [].constructor(3 - grupo.length)"></td> <!-- Células vazias -->
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<!-- Tabela de Processos Passivos -->
<div class="table-container mt-3">
  <table class="table tabela-tres-colunas" *ngIf="data.tipoPesquisa == 4 && data.dados?.processos?.processosPassivo?.length > 0">
    <thead class="thead-light mt-3">
      <tr class="text-center">
        <th scope="col" class="thead-top-only p-2" colspan="3">Processos Passivos</th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr class="table-info text-center" *ngFor="let grupo of processosPassivosEmGrupos">
          <td *ngFor="let processoPassivo of grupo">{{ processoPassivo }}</td>
          <td *ngFor="let i of [].constructor(3 - grupo.length)"></td> <!-- Células vazias para completar -->
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div *ngIf="data.tipoPesquisa == 4 && data.dados?.processos?.processosPassivo?.length === 0" class="text-center">
  <p>Nenhum processo passivo encontrado.</p>
</div>

<!-- Tabela de Documentos -->
<table class="table" *ngIf="data.tipoPesquisa == 5 && data.dados?.documentos?.length > 0">
  <thead class="thead-light">
    <tr class="text-center">
      <th scope="col" class="thead-top-only p-2">Documentos</th>
    </tr>
  </thead>
  <tbody class="corpo-processo">
    <ng-container>
      <tr class="table-info text-center d-flex flex-column" *ngFor="let documento of data.dados?.documentos"> 
        <td>{{ documento }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div *ngIf="data.tipoPesquisa == 5 && data.dados?.documentos?.length === 0" class="text-center">
  <p>Nenhum documento encontrado.</p>
</div>

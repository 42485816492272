<div class="container">
    <div *ngFor="let form of forms; let i = index" class="form-container">
      <!-- <h3>Ativo {{ i + 1 }}</h3> -->
      <div class="row d-flex mt-2">
        <div class="col">
          <form [formGroup]="form">
            <mat-form-field appearance="outline" class="m-1">
              <mat-label>Tipo de Passivo</mat-label>
              <mat-select formControlName="tipoId" name="tipoId{{i}}">
                <mat-option value="1">Dívida Fiscal </mat-option>
                <mat-option value="2">Trabalhista</mat-option>
                <mat-option value="3">Dívida Judicial</mat-option>
                <mat-option value="4">Outros Passivos</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- ============ DIVIDA FISCAL ============= -->
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 1">
              <mat-label>Órgão responsável</mat-label>
              <input formControlName="orgaoResponsavel" name="orgaoResponsavel{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 1">
              <mat-label>Descrição</mat-label>
              <input formControlName="descricao" name="descricao{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 1">
              <mat-label>Cidade</mat-label>
              <input formControlName="cidade" name="cidade{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 1">
              <mat-label>UF</mat-label>
              <input formControlName="uf" name="uf{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 1">
              <mat-label>Data do Débito</mat-label>
              <input matInput type="date" formControlName="dataDebito" name="dataDebito{{i}}">
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 1">
              <mat-label>Valor de Débito</mat-label>
              <input formControlName="valorDebito" name="valorDebito{{i}}" matInput  currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
            </mat-form-field>
  
            <!-- ============ trabalhista ============= -->
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>Número do Processo</mat-label>
              <input formControlName="numProcesso" name="numProcesso{{i}}" matInput appProcessoMask maxlength="25">
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>Fase do Processo</mat-label>
              <input formControlName="faseProcesso" name="faseProcesso{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>Partes Envolvidas</mat-label>
              <input formControlName="partesEnvolvidas" name="partesEnvolvidas{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>Comarca</mat-label>
              <input formControlName="comarca" name="comarca{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>UF</mat-label>
              <input formControlName="uf" name="uf{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>Data do Débito</mat-label>
              <input matInput type="date" formControlName="dataDebito" name="dataDebito{{i}}">
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 2">
              <mat-label>Valor de Débito</mat-label>
              <input formControlName="valorDebito" name="valorDebito{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
            </mat-form-field>
  
            <!-- ============ Acordo Judicial ============= -->
  
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>Número do Processo</mat-label>
              <input formControlName="numProcesso" name="numProcesso{{i}}" matInput appProcessoMask maxlength="25">
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>Tipo do Processo</mat-label>
              <input formControlName="tipoProcesso" name="tipoProcesso{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>Partes Envolvidas</mat-label>
              <input formControlName="partesEnvolvidas" name="partesEnvolvidas{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>Comarca</mat-label>
              <input formControlName="comarca" name="comarca{{i}}" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>UF</mat-label>
              <input formControlName="uf" name="uf{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>Data do Débito</mat-label>
              <input matInput type="date" formControlName="dataDebito" name="dataDebito{{i}}">
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 3">
              <mat-label>Valor de Débito</mat-label>
              <input formControlName="valorDebito" name="valorDebito{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
            </mat-form-field>
  
            <!-- ============ OUTRAS DIVIDAS ============= -->
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 4">
              <mat-label>Descrição</mat-label>
              <input formControlName="descricao" name="descricao{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 4">
              <mat-label>Cidade</mat-label>
              <input formControlName="cidade" name="cidade{{i}}" matInput>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 4">
              <mat-label>UF</mat-label>
              <input formControlName="uf" name="uf{{i}}" matInput>
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 4">
              <mat-label>Data do Débito</mat-label>
              <input matInput type="date" formControlName="dataDebito" name="dataDebito{{i}}">
            </mat-form-field>
  
            <mat-form-field class="example-form-field" appearance="outline" class="m-1"
              *ngIf="form.get('tipoId').value == 5">
              <mat-label>Valor de Débito</mat-label>
              <input formControlName="valorDebito" name="valorDebito{{i}}"  matInput currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
            </mat-form-field>
          </form>
          <div class="d-flex justify-content-end" style="margin-top: -10px;">
            <mat-icon class="delete-icon" *ngIf="forms.length > 0" (click)="removeForm(i)">delete</mat-icon>
          </div>
          <hr>
        </div>
  
      </div>
    </div>
    <div class="m-1 mb-2 d-flex div-more">
      <div class="m-2 d-flex align-items-center">
        <mat-icon class="icon-more">add</mat-icon> <span class="more" (click)="addForm()">&nbsp;Adicionar Passivo</span>
      </div>
      <div class="m-2 d-flex align-items-center" (click)="openModalAnexo()">
        <mat-icon class="icons-action-file">attach_file</mat-icon> <span class="more">&nbsp;Importar Passivos</span>
      </div>
      <div class="m-2 d-flex align-items-center" (click)="deletarTodosPassivos()">
        <mat-icon class="icons-action-delete">delete</mat-icon> <span class="more">&nbsp;Deletar Todos os Passivos</span>
      </div>
    </div>
  </div>
  
<div class="header text-center d-flex justify-content-between">
  <div></div>
  <h2>Validação dos documentos para cruzamento</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -4px;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="container mt-2">
  <section style="margin-top: -30px;">
    <!-- Ajuste do margin-top -->
    <div class="box-wrap mt-2">
      <div class="table-wrap tableFixHead" style="max-height: 400px; overflow-y: auto;"
        *ngIf="documentosProcessados.length > 0">
        <p *ngIf="hasDocumentWithoutName" class="alert alert-warning text-white" style="background-color: #ffcc00;">
          Atenção: Alguns documentos não possuem nome associado.
        </p>
        <!-- Ajuste do tamanho da tabela -->
        <table style="width: 100%;">
          <!-- Tabela ocupando 100% da largura disponível -->
          <thead>
            <tr>
              <th>Documento</th>
              <th>Status</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let o of documentosProcessados">
              <td class="otherStatus">{{o.numero}}</td>
              <td class="otherStatus d-flex align-items-center">
                <div [ngClass]="o.nome ? 'circle-green' : 'circle-red'"></div>
                {{o.nome ? o.nome : 'Não foi encontrada nenhuma pesquisa para esse documento'}}
              </td>
              <td class="otherStatus">{{o.dataCadastro ? (o.dataCadastro | date: 'dd/MM/yyyy') : '-'}}</td>
            </tr>
          </tbody>
        </table>

        <p class="text-warning-red" *ngIf="verificaDatas()">* As datas superiores a 6 meses de pesquisa, sugerem que sejam refeitas a 
          fim de atualizar os dados para esse cruzamento </p>
      </div>

      <div class="table-wrap tableFixHead text-center" *ngIf="documentosProcessados.length == 0">
        <p>Nenhum documento encontrado</p>
      </div>
    </div>

    <div class="row d-flex" style="margin-top: -24px;" *ngIf="documentosProcessados.length > 0">
      <div class="col" *ngIf="!identificador?.tag">
        <div class="chip-container">
          <mat-chip *ngFor="let item of addedItems" (removed)="removeChip(item)"
            class="rounded-chip d-flex justify-content-center align-items-center">
            {{ item?.tag }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </div>
        
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%;">
          <mat-label *ngIf="addedItems?.length == 0">{{labelTag}}</mat-label>
          <input *ngIf="addedItems?.length == 0" #autoInput matInput type="text" [formControl]="myControl"
            [matAutocomplete]="auto" (keydown.enter)="onEnter($event)"
            [ngClass]="addedItems?.length > 0 ? 'mt-3' : '' ">
          <mat-hint class="mt-3">Escreva e aperte ENTER para adicionar a {{labelTag}}</mat-hint>
          
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredTags | async" class="d-flex justify-content-between">
              <div class="d-flex justify-content-between">
                <div (click)="addChip(option)" style="width: -webkit-fill-available;">{{ option.tag }}</div>
                <div>
                  <mat-icon class="trashIcon" (click)="deletarTagExistente(option)">delete</mat-icon>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="col d-flex justify-content-between totalItens align-items-center">
        <p class="total" *ngIf="!isLoading && identificador?.tag">Identificador:
          <span>{{identificador?.tag}}</span>
        </p>
      </div>
    </div>

    <div class="col d-flex justify-content-end" style="margin-top: -10px;">
      <!-- Botão de Cancelar durante o processamento -->
      <button class="gs-botaoAzulPadrao btn btn-primary btn-cancel" *ngIf="isLoading" mat-dialog-close>
        Cancelar
      </button>

      <!-- Botões exibidos após o término do processamento -->
      <div class="d-flex justify-content-between mt-3">
        <button class="gs-botaoAzulPadrao btn btn-primary btn-cancel" mat-dialog-close>
          Cancelar
        </button>
        <button
          class="gs-botaoAzulPadrao btn btn-primary btn-go d-inline-block ml-2"
          (click)="enviarPesquisaParaProcessamento()">
          {{ hasDocumentWithoutName ? 'Cruzar mesmo assim?' : 'Cruzar Dados' }}
          <!-- <mat-icon>search</mat-icon> -->
        </button>
      </div>
    </div>

  </section>
</div>

<div class="container">
  <div class="content-box">
    <h3 class="identificador-title text-center">Resultado do Cruzamento de Dados</h3>
    <div class="row m-2" *ngIf="dadosAba">
      <div class="col col-grey">
        <p><b>Identificação:</b> {{dadosAba?.idpesquisa?.identificacao}}</p>
      </div>

      <div class="col col-grey">
        <p><b>Emitido por:</b> {{dadosAba?.idpesquisa?.usuarioCadastro}}</p>
      </div>

      <div class="col col-grey">
        <p><b>Data:</b> {{dadosAba?.idpesquisa?.data | date: 'dd/MM/yyyy HH:mm' }}</p>
      </div>
      <div class="row" *ngIf="dadosAba">
        <div class="col col-grey">
          <p><b>Total Emails:</b> {{getTotalEmails()}}</p>
        </div>
  
        <div class="col col-grey">
          <p><b>Total Telefones:</b> {{getTotalTelefones()}}</p>
        </div>
  
        <div class="col col-grey">
          <p><b>Total Endereços:</b> {{getTotalEnderecos()}}</p>
        </div>

        <div class="col col-grey">
          <p><b>Total Processos:</b> {{getTotalProcessosAtivos() + getTotalProcessosPassivos() }}</p>
        </div>
      </div>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel class="py-2 mt-3" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            TOTAL DE DOCUMENTOS PARA CRUZAMENTO {{ infoPesquisa?.length }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <loader-only *ngIf="loadingOrigem"></loader-only>
      <div style="width: 100%;" class="content-box">
        <table class="table w-100" *ngIf="!loadingOrigem && dadosOrigemPesquisa?.length > 0">
          <thead class="thead-light">
            <tr class="text-center">
              <th scope="col" class="thead-top-only p-2">
               Documentos Escolhidos Para o Cruzamento de Dados
              </th>
            </tr>
          </thead>
          <tbody class="corpo-processo">
            <ng-container *ngFor="let info of dadosOrigemPesquisa">
              <tr class="table-info text-center">
                <td style="width: 15.5%;">
                  <div class="cell-flex">
                    <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                    <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                    <span class="plus-data" *ngIf="info?.documentos?.length > 1" (click)="openModalDados(info, 5)">{{info?.documentos?.length}}</span>
                  </div>
                </td>
                <td style="width: 27%; text-align: left;" [ngClass]="pesquisadoOrigem ? 'bolder': ''">{{ info?.nome }}</td>
                <td style="width: 12.5%;">
                  <div class="cell-flex">
                    <span>Endereços</span>
                    <span class="plus-data" *ngIf="info?.enderecos?.length > 0" (click)="openModalDados(info, 1)"> {{ info?.enderecos.length }}</span>
                  </div>
                </td>
                <td style="width: 12.5%;">
                  <div class="cell-flex">
                    <span>Telefones</span>
                    <span class="plus-data" *ngIf="info?.telefones?.length > 0" (click)="openModalDados(info, 3)">{{ info?.telefones.length }}</span>
                  </div>
                </td>
                <td style="width: 12.5%;">
                  <div class="cell-flex">
                    <span>Emails</span>
                    <span class="plus-data" *ngIf="info?.emails?.length > 0" (click)="openModalDados(info, 2)"> {{ info?.emails.length }}</span>
                  </div>
                </td>
                <td style="width: 15%;">
                  <div class="cell-flex px-3">
                    <span>Processos</span>
                    <span class="plus-data"
                      *ngIf="info?.processos.processosAtivo?.length > 0 || info?.processos.processosPassivo.length > 0"
                      (click)="openModalDados(info, 4)"> {{ info?.processos.processosAtivo.length + info?.processos.processosPassivo.length }}</span>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
  
      <mat-paginator *ngIf="totalItemsOrigem > 15" [length]="totalItemsOrigem" [pageSize]="pageSizeOrigem" [pageIndex]="pageIndexOrigem" (page)="changePageOrigem($event)">
      </mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
  
  <mat-accordion>
    <mat-expansion-panel class="py-2 mt-3" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="mb-0 d-flex align-items-center">
            DADOS CRUZADOS E COMPARTILHADOS
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <div class="container content-box p-4">
        <p class="text-center identificador-title mt-1">Utilize os parametros abaixo para obter os dados cruzados</p>
        <div class="row d-flex align-items-center justify-content-start">
          <!-- <div class="col-md d-flex align-items-center justify-content-center">
            <mat-slide-toggle [checked]="reverterFiltro === 1" (change)="reverterFiltro = $event.source.checked ? 1 : 0" name="reverterFiltro">Inverter Filtros</mat-slide-toggle>
          </div> -->
          <div class="col-md-3 d-flex align-items-center justify-content-start" *ngIf="exibeSegNivel">
            <mat-slide-toggle [checked]="segundoNivel" (change)="segundoNivel = $event.source.checked ? true : false" name="segundoNivel">
              Segundo Nível
            </mat-slide-toggle>
          </div>
        </div>
      
        <div class="row d-flex align-items-center">
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Emails <span>{{selectedEmails.length}}</span></mat-label>
                <mat-select [(ngModel)]="selectedEmails" name="emails" [multiple]="true">
                    <mat-checkbox [checked]="isAllSelected('emails')" (click)="toggleSelectAll('emails', $event)" style="padding: 6px 17px;">
                        {{ isAllSelected('emails') ? 'Desmarcar todos' : 'Selecionar todos' }}
                    </mat-checkbox>
                    <mat-option *ngIf="emailsListagem?.length === 0" disabled>
                        Não existe
                    </mat-option>
                    <mat-option *ngFor="let email of emailsListagem" [value]="email">
                        {{ email }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
        <!-- Endereços -->
        <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Endereços selecionados <span>{{selectedEnderecos.length}}</span></mat-label>
                <mat-select [(ngModel)]="selectedEnderecos" name="enderecos" [multiple]="true">
                    <mat-checkbox [checked]="isAllSelected('enderecos')" (click)="toggleSelectAll('enderecos', $event)" style="padding: 6px 17px;">
                        {{ isAllSelected('enderecos') ? 'Desmarcar todos' : 'Selecionar todos' }}
                    </mat-checkbox>
                    <mat-option *ngIf="enderecosListagem?.length === 0" disabled>
                        Não existe
                    </mat-option>
                    <mat-option *ngFor="let endereco of enderecosListagem" [value]="endereco">
                        {{ endereco }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
        <!-- Telefones -->
        <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Telefones <span>{{selectedTelefones.length}}</span></mat-label>
                <mat-select [(ngModel)]="selectedTelefones" name="telefones" [multiple]="true">
                    <mat-checkbox [checked]="isAllSelected('telefones')" (click)="toggleSelectAll('telefones', $event)" style="padding: 6px 17px;">
                        {{ isAllSelected('telefones') ? 'Desmarcar todos' : 'Selecionar todos' }}
                    </mat-checkbox>
                    <mat-option *ngIf="telefonesListagem?.length === 0" disabled>
                        Não existe
                    </mat-option>
                    <mat-option *ngFor="let telefone of telefonesListagem" [value]="telefone">
                        {{ telefone }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    
    <div class="row d-flex align-items-center mt-2">
        <!-- Processos -->
        <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Processos <span>{{selectedProcessos.length}}</span></mat-label>
                <mat-select [(ngModel)]="selectedProcessos" name="processos" [multiple]="true">
                    <mat-checkbox [checked]="isAllSelected('processos')" (click)="toggleSelectAll('processos', $event)" style="padding: 6px 17px;">
                        {{ isAllSelected('processos') ? 'Desmarcar todos' : 'Selecionar todos' }}
                    </mat-checkbox>
                    <mat-option *ngIf="processosListagem?.length === 0" disabled>
                        Não existe
                    </mat-option>
                    <mat-option *ngFor="let processo of processosListagem" [value]="processo">
                        {{ processo }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
        <!-- Nomes -->
        <div class="col-md-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Nome <span>{{selectedNomes.length}}</span></mat-label>
                <mat-select [(ngModel)]="selectedNomes" name="nomes" [multiple]="true">
                    <mat-checkbox [checked]="isAllSelected('nomes')" (click)="toggleSelectAll('nomes', $event)" style="padding: 6px 17px;">
                        {{ isAllSelected('nomes') ? 'Desmarcar todos' : 'Selecionar todos' }}
                    </mat-checkbox>
                    <mat-option *ngIf="nomesListagem?.length === 0" disabled>
                        Não existe
                    </mat-option>
                    <mat-option *ngFor="let nome of nomesListagem" [value]="nome">
                        {{ nome }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        </div>
      
        <div class="row mt-3">
          <div class="col d-flex justify-content-end">
            <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="filtrar()">Filtrar 
              <mat-icon>search</mat-icon>
            </button>
            <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="limparFiltros()">Restaurar Filtros</button>
          </div>
        </div>
      </div>
      
  
      <loader-only *ngIf="isLoadingDados"></loader-only>
      <table class="table w-100 mt-4" *ngIf="!isLoadingDados && dadosCruzados?.length > 0">
        <thead class="thead-light">
          <tr class="text-center">
            <th scope="col" class="thead-top-only p-2">
              Pessoas e Empresas que compartilham mesmos dados de endereços, telefones, emails e processos
            </th>
          </tr>
        </thead>
        <tbody class="corpo-processo">
          <ng-container *ngFor="let info of dadosPaginados">
            <tr class="table-info text-center">
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span *ngIf="info?.documentos?.length == 1">{{ info?.documentos }}</span>
                  <span *ngIf="info?.documentos?.length > 1">{{ info?.documentos[1] }}</span>
                  <span class="plus-data" *ngIf="info?.documentos?.length > 1" (click)="openModalDados(info, 5)">{{info?.documentos?.length}}</span>
                </div>
              </td>
              <td style="width: 27%; text-align: left;">{{ info?.nome }}</td>
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span>Endereços</span>
                  <span class="plus-data" *ngIf="info?.enderecos?.length > 0" (click)="openModalDados(info, 1)"> {{ info?.enderecos.length }}</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span>Telefones</span>
                  <span class="plus-data" *ngIf="info?.telefones?.length > 0" (click)="openModalDados(info, 3)"> {{ info?.telefones.length }}</span>
                </div>
              </td>
              <td style="width: 15%;">
                <div class="cell-flex">
                  <span>Emails</span>
                  <span class="plus-data" *ngIf="info?.emails?.length > 0" (click)="openModalDados(info, 2)"> {{ info?.emails.length }}</span>
                </div>
              </td>
              <td style="width: 18%;">
                <div class="cell-flex">
                  <span>Processos</span>
                  <span class="plus-data"
                    *ngIf="info?.processos.processosAtivo?.length > 0 || info?.processos.processosPassivo.length > 0"
                    (click)="openModalDados(info, 4)"> {{ info?.processos.processosAtivo.length + info?.processos.processosPassivo.length }}</span>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
  
      <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="changePage($event)">
      </mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
  

</div>

<div class="container">
    <div class="d-flex justify-content-start">
        <mat-icon class="arrow-back" (click)="backToListagem()">arrow_back</mat-icon>
    </div>
  <div class="title-pesq">
    <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
    <p>Precificador de Credito</p>
  </div>
  <ul id="progressbar" class="d-flex justify-content-center mt-4">
    <li (click)="goToStep(1);" [ngClass]="currentStep == 1 ? 'ativo' : ''">Atualize o valor de Crédito</li>
    <li  (click)="goToStep(2);" [ngClass]="currentStep == 2 ? 'ativo' : ''">Listar Ativos</li>
    <li (click)="goToStep(3);" [ngClass]="currentStep == 3 ? 'ativo' : ''">Listar Passivos</li>
    <li (click)="goToStep(4);" [ngClass]="currentStep == 4 ? 'ativo' : ''">Tempo de Recuperação</li>
    <li (click)="goToStep(5);" [ngClass]="currentStep == 5 ? 'ativo' : ''">Cálculo Final do Crédito</li>
  </ul>

  <!-- === step 1 ===== -->
  <div *ngIf="currentStep == 1">
    <div class="content m-4">
      <app-step-one></app-step-one>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="nextStep();">Salvar e Avançar</button>
    </div>
  </div>

  <!-- === step 2 ===== -->
  <div *ngIf="currentStep == 2">
    <div class="content m-4">
      <app-step-two [precificadorId]="precificadorIdPai"></app-step-two>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="prevStep()">Voltar</button>
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="nextStep()">Salvar e Avançar</button>
    </div>
  </div>

  <!-- === step 3 ===== -->
  <div *ngIf="currentStep == 3">
    <div class="content m-4">
      <app-step-three></app-step-three>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="prevStep()">Voltar</button>
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="nextStep()">Salvar e Avançar</button>
    </div>
  </div>

  <!-- === step 4 ===== -->
  <div *ngIf="currentStep == 4">
    <div class="content m-4">
      <app-step-four></app-step-four>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="prevStep()">Voltar</button>
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="nextStep()">Salvar e Avançar</button>
    </div>
  </div>

  <div *ngIf="currentStep == 5">
    <div class="content m-3">
      <app-step-final></app-step-final>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="prevStep()">Voltar</button>
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="visualizarRelatorio()">Visualizar</button>
      <button mat-flat-button class="gs-botaoAzulPadrao button-width m-3" (click)="gerarPdf()">Gerar PDF</button>

    </div>
  </div>

</div>

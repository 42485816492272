<div class="form-ext">
  <mat-card class="pesq-info">
    <mat-card-header class="title-pesq">
      <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
      <p>{{getTituloById(idProduto)}}</p>
    </mat-card-header>
    <form class="novapesq-form">
      <div class="container">
        <div class="row d-flex justify-content-center align-items-center">
          <!-- <div [ngClass]="listaFonteGrupo?.length > 1 ? 'col-md-2' : 'col-md'"> -->
            <!-- <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Numero do Documento</mat-label>
              <input matInput mask="CPF_CNPJ" [(ngModel)]="formPesquisa.numero" name="numero"
                (ngModelChange)="verificaTipoDocumento()">
            </mat-form-field> -->
            <div class="col-md">
              <!-- <mat-form-field class="example-full-width" appearance="outline">
                <div class="chip-container">
                  <mat-chip *ngFor="let item of docsAdded" (removed)="removeChipDoc(item)"
                    class="rounded-chip d-flex justify-content-center align-items-center">
                    {{ item }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </div>
                <mat-label *ngIf="docsAdded.length === 0">Documento</mat-label>
                <input #autoInput matInput type="text" [formControl]="docControl" mask="000.000.000-00 || 00.000.000/0000-00"
                  (keydown.enter)="onEnterDoc()" [ngClass]="docsAdded.length > 0 ? 'mt-3' : ''">
                <mat-hint class="mt-3">Escreva e aperte ENTER para adicionar o documento</mat-hint>
              </mat-form-field> -->              
              <mat-form-field class="example-full-width" appearance="outline">
                <div class="chip-container" style="max-height: 150px; overflow-y: auto;">
                  <mat-chip *ngFor="let item of docsAdded" (removed)="removeChipDoc(item)"
                    class="rounded-chip d-flex justify-content-center align-items-center">
                    {{ item }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </div>
                <mat-label *ngIf="docsAdded.length === 0">Digite um ou mais documentos</mat-label>
                <input 
                  #autoInput 
                  matInput 
                  type="text" 
                  [formControl]="docControl" 
                  mask="000.000.000-00 || 00.000.000/0000-00"
                  (keydown.enter)="onEnterDoc()" 
                  (blur)="onEnterDoc()"
                  (keydown.esc)="clearChips()"
                  (paste)="onPasteDoc($event)"
                  [ngClass]="docsAdded.length > 0 ? 'mt-3' : ''">
                <mat-hint class="mt-3">Pressione ESC para apagar todos documentos</mat-hint>
              </mat-form-field>
            </div>

          <div class="col-md" *ngIf="listaFonteGrupo?.length > 1">
            <mat-form-field appearance="outline">
              <mat-label>Selecione o Produto</mat-label>
              <mat-select [(ngModel)]="formPesquisa.idFonteGrupo" name="idFonteGrupo"
                (selectionChange)="onFonteValue($event)">
                <mat-option *ngFor="let u of listaFonteGrupo" [value]="u.fonteGrupo.id">{{u.fonteGrupo.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md col-mail">
            <mat-slide-toggle [checked]="formPesquisa.notificacao"
              (change)="formPesquisa.notificacao = $event.source.checked" name="notificacao">Enviar por E-mail?
            </mat-slide-toggle>
            <!-- <app-pesquisa-em-massa [fonteGrupo]="formPesquisa"></app-pesquisa-em-massa> -->
            <button mat-flat-button
              (click)="confirmarPesquisa()"         
              (dblClick)="confirmarPesquisa()" class="gs-botaoAzulPadrao m-3">Iniciar
              Pesquisa <mat-icon>search</mat-icon></button>

          </div>
        </div>

        <div class="row">
          <div class="col-md-6 ml-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <div class="chip-container">
                <mat-chip *ngFor="let item of addedItems" (removed)="removeChip(item)"
                  class="rounded-chip d-flex justify-content-center align-items-center">
                  {{ item.tag }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </div>
              <mat-label *ngIf="addedItems?.length == 0">{{labelTag}}</mat-label>
              <input #autoInput matInput type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"
                (keydown.enter)="onEnter($event)" [ngClass]="addedItems?.length > 0 ? 'mt-3' : '' ">
              <mat-hint class="mt-3">Escreva e aperte ENTER para adicionar a {{labelTag}}</mat-hint>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredTags | async" class="d-flex justify-content-between">
                  <div class="d-flex justify-content-between">
                    <div (click)="addChip(option)" style="width: -webkit-fill-available;">{{ option.tag }}</div>
                    <div>
                      <mat-icon class="trashIcon" (click)="deletarTagExistente(option)">delete</mat-icon>
                    </div>
                  </div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="listaFontesAdicionaisPatrimonial?.length > 0"
          class="mt-4 selects d-flex justify-content-center flex-column text-center">
          <p class="p-text">Escolha abaixo pesquisas prévias ou qualificadas</p>
          <div class="row mt-2 checks">
            <div class="d-flex justify-content-left">
              <mat-checkbox [checked]="true" style="pointer-events: none;">Pacote Básico</mat-checkbox>
            </div>
            <ng-container *ngFor="let u of listaFontesAdicionaisPatrimonial">
              <div class="col-12 col-md-4">
                <div class="d-flex align-items-center">
                  <mat-icon class="tooltip-icon mt-1" matTooltip={{u.fonteConfiguracao.descricaoFonte}}>help</mat-icon>
                  <mat-checkbox [value]="u" [checked]="checked" (change)="selecionarFonteAdicional($event, u)">
                    {{ u.fonteConfiguracao.nome }}
                  </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Accordions para cidades -->
        <div class="d-flex justify-content-center flex-column cidadesEstados"
          *ngIf="hasValorAdicionalComCidade() && formPesquisa.valorAdicional.length > 0">
          <p class="p-text text-center">Selecione as cidades de Minas Gerais que deseja pesquisar</p>
          <div class="row mt-1">
            <ng-container *ngFor="let estado of formPesquisa.valorAdicional">
              <div class="col-md-9">
                <ng-container *ngIf="hasValorAdicionalComCidadeEstado(estado)">
                  <mat-accordion class="my-2">
                    <mat-expansion-panel [expanded]="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon class="city-icon">location_city</mat-icon>&nbsp; {{estado.fonteConfiguracao.nome}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row mt-2 checks content-cidades">
                        <ng-container *ngIf="estado.fonteConfiguracao.configuracaoParametros.length > 0">

                          <ng-container>
                            <div class="col-md-12" style="padding: 1px !important;">
                              <mat-form-field  appearance="outline">
                                <input matInput placeholder="Digite para filtrar as cidades..." [(ngModel)]="filterCidade" name="filterCidade" (input)="applyFilter(estado.fonteConfiguracao.configuracaoParametros)">
                              </mat-form-field>
                              <div class="d-flex align-items-center">
                                <div class="content-checkbox-cidades">
                                  <mat-checkbox class="check-cidades" (change)="selecionarTodasCidades($event)">
                                  </mat-checkbox>
                                </div>
                                <label for="check" class="label-cidades">Selecionar todas</label>
                              </div>
                            </div>
                          </ng-container>

                          <!-- <div class="d-flex justify-content-left">
                            <mat-checkbox (change)="selecionarTodasCidades($event)">Selecionar todas</mat-checkbox>
                          </div> -->
                          <ng-container
                            *ngFor="let item of filteredCidades; let i = index">
                            <div class="col-md-12 p-0" [ngClass]="{ 'bg-color': i % 2 === 0, 'bg-white': i % 2 !== 0 }">
                              <div class="d-flex align-items-center">
                                <div class="content-checkbox-cidades">
                                  <mat-checkbox class="check-cidades" [value]="item" [checked]="item.selecionado"
                                    (change)="selecionarParametrosCidades($event, item)">
                                  </mat-checkbox>
                                </div>
                                <label for="check" class="label-cidades">{{ item.descricao }}</label>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="filteredCidades.length == 0">
                          <div class="col-md-12">
                            <p class="text-center">Nenhuma cidade</p>
                          </div>
                        </ng-container>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-container>
              </div>
              <div class="col-md-3" *ngIf="hasValorAdicionalComCidadeEstado(estado)">
                <div class="draggable p-3 d-flex flex-column justify-content-center text-center">
                  <div class="qtd-border mt-2">
                    <p class="estados-label">Pesquisas Qualificadas:</p>
                    <p><span class="qtd-city"> {{cidadesSelecionadas.length}} </span></p>
                  </div>
                  <div class="qtd-border mt-2">
                    <p class="estados-label">Valor Total do Consumo:</p>
                    <p><span class="qtd-city"> R${{(valorCidades + valorEstados).toFixed(2)}} </span></p>
                  </div>
                  <!-- <button mat-flat-button [disabled]="!desabilitaPesquisa() || isDisabled"
                    (click)="verificaTipoDocumento(); confirmarPesquisa()"
                    (dblClick)="verificaTipoDocumento(); confirmarPesquisa()"
                    class="gs-botaoAzulPadrao m-3 btn-qtd"
                    (keydown.enter)="disableEnterKey($event)">Iniciar
                    Pesquisa <mat-icon>search</mat-icon></button> -->
                </div>
              </div>
            </ng-container>
          </div>
        </div>



        <!-- Checkboxes para fontes adicionais -->
        <div *ngIf="listaFontesAdicionais?.length > 0"
          class="mt-4 selects d-flex justify-content-center flex-column text-center">
          <p class="p-text">Este Produto oferece as informações adicionais listadas abaixo. Deseja Incluir na Pesquisa?
          </p>
          <div class="row mt-2 checks">
            <ng-container *ngFor="let u of listaFontesAdicionais">
              <div class="col-12 col-md-4">
                <div class="d-flex align-items-center">
                  <mat-checkbox [value]="u" [checked]="u.fonteConfiguracao.selecionado" [(ngModel)]="checkboxPrincipal"
                    [ngModelOptions]="{standalone: true}" (change)="selecionarFonteAdicional($event, u)">
                    {{ u.fonteConfiguracao.nome }}
                  </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Checkboxes para parâmetros adicionais -->
        <div *ngIf="listaConfiguracoesParametros?.length > 0"
          class="mt-4 selects d-flex justify-content-center flex-column text-center">
          <p class="p-text">Parâmetros Adicionais</p>
          <div class="mt-2 d-flex justify-content-center flex-column text-center">
            <div class="row mt-2 checks">
              <ng-container *ngFor="let u of listaConfiguracoesParametros">
                <div class="col d-flex">
                  <div class="d-flex align-items-center"
                    *ngIf="u.tipoFormatacao == 'boolean' && u.idParametro == 'FATURAMENTO'">
                    <mat-checkbox [value]="u" [checked]="u.selecionado" (change)="selecionarParametros($event, u)"
                      [disabled]="tipoDocumento != u.tipoDocumento">
                      {{ u.descricao }}
                    </mat-checkbox>
                  </div>
                  <div class="d-flex align-items-center"
                    *ngIf="u.tipoFormatacao == 'boolean' && u.idParametro != 'FATURAMENTO'">
                    <mat-checkbox [value]="u" [checked]="u.selecionado" (change)="selecionarParametros($event, u)">
                      {{ u.descricao }}
                    </mat-checkbox>
                  </div>
                  <div class="d-flex align-items-center" *ngIf="u.tipoFormatacao == 'datetime'">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{u.descricao}}</mat-label>
                      <input matInput [(ngModel)]="formParametros[u.descricao]" type="date"
                        [disabled]="tipoDocumento != u.tipoDocumento">
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
  <div class="separador"></div>
  <mat-tab-group [selectedIndex]="selectedIndex" class="mt-4"
    (selectedTabChange)="atualizarIndiceAbaSelecionada($event)">
    <mat-tab *ngFor="let aba of abas; let i = index" [label]="aba.titulo" class="mt-4">
      <ng-template mat-tab-label class="d-flex align-items-center">
        <div class="col">{{aba.titulo}}</div>
        <button *ngIf="i > 0" mat-icon-button aria-label="Fechar" (click)="fecharAba(i)">
          <mat-icon class="icon-close-tab">close</mat-icon>
        </button>
      </ng-template>
      <mat-card class="pesq-re" *ngIf="aba.titulo == 'Histórico'" style="min-height: 30vw;">
        <div class="header-pesq">
          <div class="title-pesq">
            <img class="img-title-pesq" src="../../../assets/icones/lupa-azul.svg" alt="view">
            <p>Histórico de Pesquisas Realizadas</p>
          </div>
          <div class="container">
            <form class="novapesq-form">
              <div class="container">
                <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-2">
                    <mat-form-field appearance="outline">
                      <mat-label>Status</mat-label>
                      <mat-select [(ngModel)]="formFiltro.status" name="status">
                        <mat-option value="">Todos</mat-option>
                        <mat-option value="2">Aguardando Processamento</mat-option>
                        <mat-option value="3">Em Processamento</mat-option>
                        <mat-option value="5">Em Validação</mat-option>
                        <mat-option value="4">Finalizada</mat-option>
                        <mat-option value="6">Cancelada</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>CPF/CNPJ</mat-label>
                      <input matInput mask="CPF_CNPJ" [(ngModel)]="formFiltro.numero" name="numero"
                        (keyup.enter)="pesquisarClicked()">
                    </mat-form-field>
                  </div>
                  <div class="col-md">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Nome</mat-label>
                      <input matInput [(ngModel)]="formFiltro.nome" name="nome" (keyup.enter)="pesquisarClicked()">
                    </mat-form-field>
                  </div>
                  <div class="col-sm">
                    <mat-form-field appearance="outline">
                      <mat-label>{{labelTag}}</mat-label>
                      <mat-select [(ngModel)]="formFiltro.tag" name="tag">
                        <mat-option value="">Todos</mat-option>
                        <mat-option *ngFor="let option of tagIds" [value]="option.tagId">{{option.tag}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="d-flex row p-0" *ngIf="isProcessos || isDossie">
                    <div class="col-md">
                      <mat-form-field appearance="outline">
                        <mat-label>Tipo de Ação</mat-label>
                        <mat-select [(ngModel)]="formFiltro.tipoAcao" name="tipoAcao" multiple>
                          <mat-option *ngFor="let acao of tipoAcao" [value]="acao.tipoAcao">
                            {{acao.tipoAcao}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
  
                    <div class="col-md custom-field-container">
                      <div class="custom-select">
                        <mat-form-field appearance="outline">
                          <mat-label>Tipo</mat-label>
                          <mat-select [(ngModel)]="formFiltro.operadorValor" name="operadorValor">
                            <mat-option value="maior">Maior que</mat-option>
                            <mat-option value="menor">Menor que</mat-option>
                            <mat-option value="igual">Igual a</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      
                        <div class="custom-input">
                          <input class="input-valor" 
                          id="valor" 
                          [(ngModel)]="formFiltro.valor" 
                          name="valor" 
                          currencyMask 
                          placeholder="Digite o valor"
                          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:' left' }">
                        </div>
                    </div>
                    
  
                    <div class="col-md">
                      <mat-form-field appearance="outline">
                        <mat-label>Autor/Réu (tipo da parte)</mat-label>
                        <mat-select [(ngModel)]="formFiltro.autorReu" name="autorReu">
                          <mat-option value="autor">Autor</mat-option>
                          <mat-option value="reu">Réu</mat-option>
                          <mat-option value="nao identificado">Não Identificado</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
  
                    <div class="col-md">
                        <mat-form-field appearance="outline" class="example-full-width">
                          <mat-label>Status do Processo</mat-label>
                          <mat-select [(ngModel)]="formFiltro.statusProcesso" name="statusProcesso" multiple>
                            <mat-option *ngFor="let status of processoStatus" [value]="status.statusProcesso">
                              {{status.statusProcesso}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md col-mail">
                    <mat-form-field class="example-form-field" appearance="outline">
                      <mat-label>Periodo</mat-label>
                      <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker"
                        [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end">
                        <input matStartDate placeholder="DD/MM/AAAA" formControlName="start"
                          [(ngModel)]="formFiltro.cadastroDe" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                        <input matEndDate placeholder="DD/MM/AAAA" formControlName="end"
                          [(ngModel)]="formFiltro.cadastroAte" name="CadastroAte" (keyup.enter)="pesquisarClicked()">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                      <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                    </mat-form-field>
                    <button mat-flat-button (click)="pesquisarClicked()" class="gs-botaoAzulPadrao m-3">Filtrar
                      <mat-icon>search</mat-icon></button>
                    <button mat-flat-button (click)="clearForm(); pesquisarClicked()"
                      class="gs-botaoAzulPadrao m-3">Limpar Filtros</button>
                    <button  [ngClass]="pesquisasSelecionadas?.length == 0 ? 'button-disabled': ''" mat-flat-button (click)="exportarPesquisaLote()"
                      class="gs-botaoAzulPadrao m-3">
                      <mat-icon>download</mat-icon> Exportar Pesquisas Selecionadas
                    </button>
                    <button  [ngClass]="pesquisasSelecionadas?.length == 0 ? 'button-disabled': ''" mat-flat-button (click)="exportarExcelLote()"
                      class="gs-botaoAzulPadrao m-3">
                      <mat-icon>download</mat-icon> Exportar para excel unificado
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="separador"></div>
        <div class="resultado d-flex justify-content-center"
          [ngClass]="pesquisasSelecionadas?.length > 0 ? '': ''">
          <div class="line-center"></div>

          <div class="pesq-table" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()" [infiniteScrollUpDistance]="1.5" [scrollWindow]="false">

            <div class="outer-wrapper">
              <div style="text-align: center;" *ngIf="resultadoPesquisa?.length == 0">
                Nenhuma pesquisa encontrada.
              </div>
              <div class="table-wrapper" *ngIf="resultadoPesquisa?.length != 0">
                <div class="aviso-qualificada text-center" *ngIf="mensagemQualificada != '' ">
                  <p class="text-qualificada">
                    <mat-icon class="pt-1">warning</mat-icon>&nbsp; {{mensagemQualificada}}
                  </p>
                </div>
                <table class="table-grid">
                  <thead class="thead-grid">
                    <th class="th-grid">
                      <mat-checkbox (change)="selecionarTodosItens($event)"></mat-checkbox>
                      Todas
                    </th>
                    <th class="th-grid">{{labelTag}}</th>
                    <th class="th-grid">Relatório</th>
                    <th class="th-grid">CPF/CNPJ</th>
                    <th class="th-grid">Nome</th>
                    <th class="th-grid">Emissão</th>
                    <th class="th-grid">Data</th>
                    <th class="th-grid">Status</th>
                    <th class="th-grid">Progresso</th>
                    <th class="th-grid">Ações</th>
                  </thead>
                  <tbody class="tbody-grid">
                    <tr *ngFor="let o of resultadoPesquisa" class="tr-grid">
                      <td class="td-grid">
                        <div class="border-column">
                          <mat-checkbox [checked]="isItemSelected(o.pesquisa)"
                            (change)="toggleItemSelection(o.pesquisa)"></mat-checkbox>
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column hover-tag" *ngIf="o.pesquisa.tags?.length > 0"
                          (click)="editarTag(o.pesquisa)" title="Clique para editar {{labelTag}}">
                          {{o.pesquisa?.tags[0]?.clienteTag.tag }}</div>
                        <div class="border-column hover-tag" *ngIf="o.pesquisa.tags == 0"
                          (click)="editarTag(o.pesquisa)" title="Clique para editar {{labelTag}}"> - </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column d-flex align-items-center">
                          <div class="circulo-qualificada" *ngIf="o.temQualificada"></div>
                          {{ajustaNome(o.pesquisa.fonteGrupo.produto.descricao,28)}}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{o.pesquisa.numero}}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{ajustaNome(o.pesquisa?.nome, 40)}}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{o.pesquisa.usuarioCadastro?.nome}}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{ o.pesquisa.dataCadastro | date: 'dd/MM/yyyy HH:mm' }}
                        </div>
                      </td>
                      <td class="td-grid">
                        <div class="border-column">
                          {{ StatusPesquisa[o.pesquisa.status] == 'ResolucaoManual' ? 'Aguardando liberação' : StatusPesquisa[o.pesquisa.status] }}
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.Rascunho" class="td-grid">
                        <div class="border-column">
                          2%
                          <div class="loader dot-space">
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                              class="loader__dot">.</span>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.ResolucaoAutomatica" class="td-grid">
                        <div class="border-column">
                          {{ o.fontesProcessadas && o.fontes ? ((100 * (o.fontesProcessadas / o.fontes)).toFixed(0) +'%') : '0%' }}
                          <div class="loader dot-space">
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                              class="loader__dot">.</span>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.ResolucaoManual" class="td-grid">
                        <div class="border-column">
                          {{ (100 * (o.fontesProcessadas / o.fontes)).toFixed(0) - 1 >= 1 ? (100 * (o.fontesProcessadas / o.fontes)).toFixed(0) - 1 +'%' : 1 + '%'}}
                          <div class="loader dot-space">
                            <span class="loader__dot">.</span><span class="loader__dot">.</span><span
                              class="loader__dot">.</span>
                          </div>
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.Finalizada" class="td-grid">
                        <div class="border-column">
                          100%
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.FinalizadaParcial" class="td-grid">
                        <div class="border-column">
                          100%
                        </div>
                      </td>
                      <td *ngIf="o.pesquisa.status == StatusPesquisa.Cancelada" class="td-grid">
                        <div class="border-column">
                          -
                        </div>
                      </td>
                      <td>
                        <ul class="ul-table">
                          <li class="li-table" *ngIf="o.pesquisa.status != StatusPesquisa.Cancelada"><button
                              [disabled]="o.pesquisa.status == StatusPesquisa.Cancelada"
                              (click)="adicionarAba(o.pesquisa.numero, o.id, false, idProduto, undefined, o.pesquisa.valorAdicional)"><img
                                class="icon-table" src="../../../assets/icones/ICONE-NOVO-VISUALIZAÇÃO.svg" width="20px"
                                height="20px" title="Visualizar a pesquisa em tela"></button></li>
                          <li class="li-table"><button
                              [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada && o.pesquisa.status != StatusPesquisa.FinalizadaParcial"
                              (click)="exportarClicked(o)"><img class="icon-table" width="20px" height="20px"
                                src="../../../assets/icones/ICONE-NOVO-PDF.svg"
                                title="Exportar a pesquisa em PDF"></button></li>
                          <li class="li-table"><button
                              [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada && o.pesquisa.status != StatusPesquisa.FinalizadaParcial"
                              (click)="exportarExcelClicked(o)"><img class="icon-table" width="20px" height="20px"
                                src="../../../assets/icones/ICONE-NOVO-EXCEL.svg"
                                title="Exportar a pesquisa em Excel"></button></li>
                          <li class="li-table"><button
                              [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada && o.pesquisa.status != StatusPesquisa.FinalizadaParcial"
                              (click)="exportarReciboClicked(o)"><img class="icon-table" width="20px" height="20px"
                                src="../../../assets/icones/novo-icone-recibo.svg" title="Emitir o recibo"></button>
                          </li>
                          <li class="li-table" *ngIf="isSuzano"><button
                              [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada && o.pesquisa.status != StatusPesquisa.FinalizadaParcial"
                              (click)="refazerPesquisaExistente(o.pesquisa)"><mat-icon style="color: grey;"  matTooltip="Refazer Pesquisa">cached</mat-icon></button>
                          </li>
                          <!-- <li class="li-table" [hidden]="!isSuzano"><button [disabled]="o.pesquisa.status != StatusPesquisa.Finalizada" (click)="exportarLGPD(o)"><img class="icon-table" width="20px" height="20px" src="../../../assets/icones/novo-icone-recibo.svg" title="Clique para fazer o download/visualizar do conteúdo extraído da fonte"></button></li> -->
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="pesq-re" *ngIf="aba.titulo != 'Histórico'">
        <app-visao-em-tela [aba]="aba"></app-visao-em-tela>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
  
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, interval, takeWhile, throwError, timer } from 'rxjs';
import { PesquisaService } from 'src/app/shared/services/http/pesquisa.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { LinhaTempoComponent } from 'src/app/views/processo/visao-processo/linha-tempo/linha-tempo.component';

@Component({
  selector: 'aba-dados-cadastrais',
  templateUrl: './dados-cadastrais.component.html',
  styleUrls: ['./dados-cadastrais.component.scss']
})
export class DadosCadastraisComponent implements OnInit {

  @Input() dadosAba: any
  idPesquisa;
  isLoadingCadastrais = false;
  infoCadastraisGeral = [];
  @Output() arrayChange: EventEmitter<any[]> = new EventEmitter<any[]>();


  isLoadingTelefones = false;
  isLoadingEnderecos = false;
  isLoadingEmails = false; 
  isLoadingCadastraisGeral = false;
  isLoadingSintese = false
  exibeCadastrais = false
  sintese = [];
  infoCadastrais = [];
  documento;
  enderecoUrl;
  enderecoUrlCompleto;
  objetoEndereco = {
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: ''
  };
  url;
  enderecoCompleto;

  isProcessoOuDossie = false;
  isAnaliseCredito = false;
  isDossie = false;

  infoCadastraisTelefone: any = []
  infoCadastraisEmails: any = [];
  infoCadastraisEnderecos: any = []

  exibirDadosCadastrais = true;
  exibirSinteseProcesso = true;
  exibirSinteseCredito = true;
  exibeAnaliseCredito = true;
  exibeReprocessarSintese=  false;

  isLoadingPdf =  true;
  porcentagemPesquisa;
  statusPesquisa;



  constructor(
    public dialog: MatDialog,
    private ui: UiService,
    private toastr: ToastrService,
    private pesquisaService: PesquisaService,
    public sanitizer: DomSanitizer,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.documento = this.dadosAba.titulo.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (this.dadosAba.id.id) {
      this.idPesquisa = this.dadosAba.id.id
    }
    else {
      this.idPesquisa = this.dadosAba.id
    }
    if (this.dadosAba.novaPesquisa && this.router.url != '/credito') {
      this.consultaDadosCadastraisEmail(this.documento);
      this.consultaDadosCadastraisEndereco(this.documento);
      this.consultaDadosCadastraisTelefone(this.documento);
      this.consultaDadosCadastraisGeral(this.documento);
      this.exibeCadastrais = true
    }
    if(!this.dadosAba.novaPesquisa && this.router.url != '/credito'){
      this.getInfoCadastrais();
    }

    if (this.dadosAba.novaPesquisa && this.router.url == '/cadastrais') {
      this.exibeCadastrais = true;
      this.consultaDadosCadastraisGeral(this.documento)
    }

    if (!this.dadosAba.novaPesquisa && this.router.url == '/cadastrais') {
      this.exibeCadastrais = true;
      this.getInfoCadastrais();
    }

  // ===== credito ========
    if(this.dadosAba.novaPesquisa && this.router.url == '/credito'){
      this.isAnaliseCredito = true
      this.getinfoCadastraisCredito(this.idPesquisa);
    }

    if(!this.dadosAba.novaPesquisa && this.router.url == '/credito'){
      this.isAnaliseCredito = true
      this.getinfoCadastraisCredito(this.idPesquisa);
    }

  }

  getInfoCadastrais() {
    this.isLoadingCadastrais = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getDadosCadastraisPesquisa(this.idPesquisa).subscribe(
        response => {
          if (response) {
            console.log(response)
            this.infoCadastrais = response;
            if(response.cpf){
              this.objetoEndereco.endereco = response.enderecos[0]?.logradouro;
              this.objetoEndereco.numero = response.enderecos[0]?.numero;
              this.objetoEndereco.bairro = response.enderecos[0]?.bairro;
              this.objetoEndereco.uf = response.enderecos[0]?.uf;
            }
            if(response.cnpj){
              this.objetoEndereco.endereco = response?.logradouro;
              this.objetoEndereco.numero = response?.numero;
              this.objetoEndereco.bairro = response?.bairro;
              this.objetoEndereco.uf = response?.uf;
            }
            this.emitArray(this.infoCadastrais)
            var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
            this.montaurlEndereco(enderecoCompleto)
            
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;

          if(error.status == 501){
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.exibirDadosCadastrais = false;
          }

          if (attempts >= 5) {
            this.isLoadingCadastrais = true;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
          }
        }
      );
    });
  }

  emitArray(dados) {
    if(this.dadosAba.novaPesquisa && this.router.url != '/credito'){
      this.arrayChange.emit(dados);
    }
    if(!this.dadosAba.novaPesquisa && this.router.url != '/credito'){
      this.arrayChange.emit(dados);
    }

    if(this.dadosAba.novaPesquisa && this.router.url == '/credito'){
        this.arrayChange.emit(dados);
      
    }
    if(!this.dadosAba.novaPesquisa && this.router.url == '/credito'){
      if(dados.dadosCpf.cpf){
        this.arrayChange.emit(dados.dadosCpf);
      }
      if(dados.dadosCnpj.cnpj){
        this.arrayChange.emit(dados.dadosCnpj);
      }
    }
  }

  montaurlEndereco(enderecourl) {
    this.enderecoUrl = enderecourl
    this.enderecoUrl = this.enderecoUrl.replaceAll(' ', '+')
    this.enderecoUrlCompleto = `https://www.google.com/maps/embed/v1/place?q=${this.enderecoUrl}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.enderecoUrlCompleto)
  }

  consultaDadosCadastraisEmail(documento){
    this.isLoadingEmails = true;
    if(this.dadosAba.dataNasc == null || this.dadosAba.dataNasc == undefined){
      this.dadosAba.dataNasc = ""
    }
    this.pesquisaService.getConsultaCadastralEmail(documento, this.dadosAba.dataNasc).subscribe(response => {
      this.ui.loaded().subscribe(() => {
       this.infoCadastraisEmails = response;
       this.isLoadingEmails = false;
      });
    });
  }

  consultaDadosCadastraisTelefone(documento){
    this.isLoadingTelefones = true;
    if(this.dadosAba.dataNasc == null || this.dadosAba.dataNasc == undefined){
      this.dadosAba.dataNasc = ""
    }
    this.pesquisaService.getConsultaCadastralTelefone(documento, this.dadosAba.dataNasc).subscribe(response => {
      this.ui.loaded().subscribe(() => {
       this.infoCadastraisTelefone = response;
       this.isLoadingTelefones = false;
      });
    });
  }

  consultaDadosCadastraisEndereco(documento){
    this.isLoadingEnderecos = true;
    if(this.dadosAba.dataNasc == null || this.dadosAba.dataNasc == undefined){
      this.dadosAba.dataNasc = ""
    }
    this.pesquisaService.getConsultaCadastralEndereco(documento, this.dadosAba.dataNasc).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.infoCadastraisEnderecos = response;
       this.isLoadingEnderecos = false;
       if(this.infoCadastraisEnderecos.tipoDocumento == 2){
          this.objetoEndereco.endereco = this.infoCadastraisEnderecos.dadosCnpj.logradouro;
          this.objetoEndereco.numero = this.infoCadastraisEnderecos.dadosCnpj.numero;
          this.objetoEndereco.bairro = this.infoCadastraisEnderecos.dadosCnpj.bairro;
          this.objetoEndereco.uf = this.infoCadastraisEnderecos.dadosCnpj.uf;
       }

       if(this.infoCadastraisEnderecos.tipoDocumento == 1){
        this.objetoEndereco.endereco = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].logradouro;
        this.objetoEndereco.numero = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].numero;
        this.objetoEndereco.bairro = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].bairro;
        this.objetoEndereco.uf = this.infoCadastraisEnderecos.dadosCpf?.enderecos[0].uf;
       }
       var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
        this.montaurlEndereco(enderecoCompleto)
      });
    });
  }

  consultaDadosCadastraisGeral(documento) {
    console.log('caiu')
    this.isLoadingCadastraisGeral = true;
    if(this.dadosAba.dataNasc == null || this.dadosAba.dataNasc == undefined){
      this.dadosAba.dataNasc = ""
    }
    this.pesquisaService.getConsultaCadastralGeral(documento, this.dadosAba.dataNasc).subscribe(response => {
      this.ui.loaded().subscribe(() => {
        this.infoCadastraisGeral = response;
        this.emitArray(this.infoCadastraisGeral)
        this.isLoadingCadastraisGeral = false;
      });
    });
  }

  getinfoCadastraisCredito(idPesquisa){
    idPesquisa = this.idPesquisa;
    this.isLoadingCadastrais = true;
    let attempts = 0;

    const subscription = timer(0, 10000).pipe(
      takeWhile(() => attempts < 5) // Limita a 5 tentativas
    ).subscribe(() => {
      this.pesquisaService.getCadastralAnaliseCredito(idPesquisa).subscribe(
        response => {
          if (response) {
            this.emitArray(response)
            this.isLoadingCadastrais = false;
            if(response.dadosCpf.cpf){
              this.infoCadastrais = response.dadosCpf;
              this.objetoEndereco.endereco = response.dadosCpf?.enderecos[0]?.logradouro;
              this.objetoEndereco.numero = response.dadosCpf?.enderecos[0]?.numero;
              this.objetoEndereco.bairro = response.dadosCpf?.enderecos[0]?.bairro;
              this.objetoEndereco.uf = response.dadosCpf?.enderecos[0]?.uf;
            }
            if(response.dadosCnpj.cnpj){
              this.infoCadastrais = response.dadosCnpj;
              this.objetoEndereco.endereco = response.dadosCnpj.logradouro;
              this.objetoEndereco.numero = response.dadosCnpj.numero;
              this.objetoEndereco.bairro = response.dadosCnpj.bairro;
              this.objetoEndereco.uf = response.dadosCnpj.uf;
            }
            // this.emitArray()
            var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
            this.montaurlEndereco(enderecoCompleto)
            
            subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
          }
        },
        error => {
          attempts++;
          if(error.status == 501){
            this.isLoadingCadastrais = false;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.exibirDadosCadastrais = false;
          }

          if (attempts >= 5) {
            // this.isLoadingCadastrais = true;
            subscription.unsubscribe(); // Interrompe o intervalo após 5 tentativas
            this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
            this.isLoadingCadastrais = false;
          }
        }
      );
    });
  }

  getinfoCadastraisCreditoNovaPesquisa(numero){
    console.log('credito nova')
    this.isLoadingCadastrais = true;
    this.pesquisaService.getCadastralCreditoNovaPesquisa(numero).subscribe(
      response => {
        if (response) {
          console.log(response)
          this.isLoadingCadastrais = false;
          if(response.dadosCpf.cpf){
            this.infoCadastrais = response.dadosCpf;
            this.objetoEndereco.endereco = response.dadosCpf?.enderecos[0]?.logradouro;
            this.objetoEndereco.numero = response.dadosCpf?.enderecos[0]?.numero;
            this.objetoEndereco.bairro = response.dadosCpf?.enderecos[0]?.bairro;
            this.objetoEndereco.uf = response.dadosCpf?.enderecos[0]?.uf;
          }
          if(response.dadosCnpj.cnpj){
            this.infoCadastrais = response.dadosCnpj;
            this.objetoEndereco.endereco = response.dadosCnpj.logradouro;
            this.objetoEndereco.numero = response.dadosCnpj.numero;
            this.objetoEndereco.bairro = response.dadosCnpj.bairro;
            this.objetoEndereco.uf = response.dadosCnpj.uf;
          }
          var enderecoCompleto = `${this.objetoEndereco.endereco + '+' + this.objetoEndereco.numero + '+' + this.objetoEndereco?.bairro + '+' + this.objetoEndereco.cidade + '+' + this.objetoEndereco.uf}`;
          console.log(enderecoCompleto)
          this.montaurlEndereco(enderecoCompleto)
          
          // subscription.unsubscribe(); // Interrompe o intervalo após uma resposta bem-sucedida
        }
      },
      error => {
        if(error.status == 501){
          this.isLoadingCadastrais = false;
          this.exibirDadosCadastrais = false;
        }
        this.toastr.warning(`Erro ao obter as informações cadastrais do pesquisado.`);
      }
    );
  }
}

<mat-accordion *ngIf="exibeRegistradoresQualificada">
  <mat-expansion-panel class="mt-3 py-2" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Qualificada
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresQualificada && !exibeMensagemQualificada" style=" width: 100%; border-collapse: collapse; table-layout: fixed;"> 
      <thead class="thead-light">
        <tr class="text-center" *ngIf="dataRegistradoresQualificada?.quantidadeDias !== null && statusPesquisa > 0">
          <th scope="col" colspan="3" class="thead-top-left p-2 "> Resultados podem retornar em até 7 dias</th>
          
          <th scope="col" colspan="2"  class="thead p-2" *ngIf="dataRegistradoresQualificada.quantidadeDias <= 7 && statusPesquisa === 4"> 
            Sua pesquisa demorou {{ dataRegistradoresQualificada.quantidadeDias }} dia(s)
          </th>
          
          <th scope="col" colspan="2" class="thead p-2" *ngIf="dataRegistradoresQualificada.quantidadeDias > 7 && statusPesquisa === 4"> 
            Sua pesquisa demorou mais de 7 dias
          </th>
          
          <th scope="col" colspan="2" class="thead p-2" *ngIf="dataRegistradoresQualificada.quantidadeDias <= 7 && statusPesquisa !== 4"> 
            Sua pesquisa está no {{ dataRegistradoresQualificada.quantidadeDias }} dia
          </th>
          
          <th scope="col" colspan="2" class="thead p-2" *ngIf="dataRegistradoresQualificada.quantidadeDias > 7 && statusPesquisa !== 4">
            Sua pesquisa está demorando mais de 7 dias
          </th>
          
          <th scope="col" colspan="2" class="thead-top-right p-2">
            <div class="d-flex align-items-end largura-fixa " style="justify-content: space-evenly;">
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 1, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 1 }">1</div>
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 2, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 2 }">2</div>
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 3, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 3 }">3</div>
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 4, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 4 }">4</div>
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 5, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 5 }">5</div>
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 6, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 6 }">6</div>
              <div class="circle" [ngClass]="{ 'circle-active': dataRegistradoresQualificada.quantidadeDias >= 7, 'circle-disabled': dataRegistradoresQualificada.quantidadeDias < 7 }">7</div>
            </div>
          </th>
        </tr>
        
        <tr *ngIf="statusPesquisa.value === null">
          <th scope="col" colspan="7" class="thead-top-only p-2">
            <div class="loader dot-space mt-2">
              <span>carregando</span>
              <span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresQualificada.dados?.length > 0; else noDataRegistradoresQualificada">
          <tr class="text-center table-info">
            <td colspan="1"><b>UF</b></td>
            <td><b>Cidade</b></td>
            <td><b>Cartório</b></td>
            <td><b>Matrícula</b></td>
            <td><b>Transcrição</b></td>
            <td colspan="2"><b>Proprietário Atual/Observação</b></td>
          </tr>
          <tr class="text-center table-info" *ngFor="let registradoresQf of dataRegistradoresQualificada.dados">
            <td colspan="1">MG</td>
            <td>{{registradoresQf?.cidade}}</td>
            <td>{{registradoresQf?.cartorio}}</td>
            <td>{{registradoresQf?.matricula}}</td>
            <td>{{registradoresQf?.numeroTranscricao}}</td>
            <td colspan="2">{{registradoresQf?.proprietarioAtual}}</td>
          </tr>
          <tr class="text-center table-info">
            <td colspan="7"><b>Observações sobre a solicitação:</b> Os cartórios que não foram exibidos deram resultados negativos</td>
          </tr>
          <tr class="text-center table-info">
            <td colspan="7"><b>Atenção:</b> Pesquisas com mais de 10 matrículas podem conter mais imóveis. Em casos como esse é necessário entrar em contato com os cartórios responsáveis.</td>
          </tr>          
        </ng-container>
        <ng-template #noDataRegistradoresQualificada>
          <tr>
            <td colspan="7" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresQualificada && exibeMensagemQualificada">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarQualificada">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresQualificada(idPesquisa, 218)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresQualificada.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresQualificada"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresRioGrandeSul">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Rio Grande do Sul
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table"  *ngIf="!isLoadingRegistradoresRioGrandeSul && !exibeMensagemRioGrandeSul">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresRioGrandeSul.dados?.length > 0; else noDataRegistradoresRioGrandeSul">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresRioGrandeSul.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresRioGrandeSul>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresRioGrandeSul && exibeMensagemRioGrandeSul">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresRioGrandeSul">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresRioGrandeSul(idPesquisa, 77)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresRioGrandeSul.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresRioGrandeSul"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresSantaCatarina">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Santa Catarina
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresSantaCatarina && !exibeMensagemSantaCatarina">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresSantaCatarina.dados?.length > 0; else noDataRegistradoresSantaCatarina">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresSantaCatarina.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresSantaCatarina>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresSantaCatarina && exibeMensagemSantaCatarina">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresSantaCatarina">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresSantaCatarina(idPesquisa, 79)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresSantaCatarina.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresSantaCatarina"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresSaoPaulo">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores São Paulo
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresSaoPaulo && !exibeMensagemSaoPaulo">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresSaoPaulo.dados?.length > 0; else noDataRegistradoresSaoPaulo">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresSaoPaulo.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresSaoPaulo>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresSaoPaulo && exibeMensagemSaoPaulo">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresSaoPaulo">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresSaoPaulo(idPesquisa, 91)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresSaoPaulo.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresSaoPaulo"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresEspiritoSanto">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Espirito Santo
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresEspiritoSanto && !exibeMensagemEspiritoSanto">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresEspiritoSanto.dados?.length > 0; else noDataRegistradoresEspiritoSanto">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresEspiritoSanto.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresEspiritoSanto>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresEspiritoSanto && exibeMensagemEspiritoSanto">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresEspiritoSanto">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresEspiritoSanto(idPesquisa, 129)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresEspiritoSanto.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresEspiritoSanto"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresRioJaneiro">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Rio de Janeiro
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresRioJaneiro && !exibeMensagemRioJaneiro">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresRioJaneiro.dados?.length > 0; else noDataRegistradoresRioJaneiro">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresRioJaneiro.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresRioJaneiro>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresRioJaneiro && exibeMensagemRioJaneiro">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresRioJaneiro">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresRioJaneiro(idPesquisa, 198)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresRioJaneiro.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresRioJaneiro"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresRondonia">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Rondônia
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresRondonia && !exibeMensagemRondonia">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresRondonia.dados?.length > 0; else noDataRegistradoresRondonia">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresRondonia.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresRondonia>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresRondonia && exibeMensagemRondonia">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresRondonia">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresRondonia(idPesquisa, 206)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
      <p>{{dataRegistradoresRondonia.mensagem}}</p>
     </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresRondonia"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresParana">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Paraná
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresParana && !exibeMensagemParana">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresParana.dados?.length > 0; else noDataRegistradoresParana">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresParana.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresParana>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresParana && exibeMensagemParana">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresParana">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresParana(idPesquisa, 207)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresParana.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresParana"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresDistritoFederal">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores DF
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresDistritoFederal && !exibeMensagemDistritoFederal">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngIf="dataRegistradoresDistritoFederal.dados?.length > 0; else noDataRegistradoresDistritoFederal">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresDistritoFederal.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresDistritoFederal>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresDistritoFederal && exibeMensagemDistritoFederal">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresDistritoFederal">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresDistritoFederal(idPesquisa, 208)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresDistritoFederal.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresDistritoFederal"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeRegistradoresMatoGrossoSul">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Registradores Mato Grosso do Sul
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingRegistradoresMatoGrossoSul && !exibeMensagemMatoGrossoSul">
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">UF</th>
          <th scope="col" class="thead p-2">Cartório</th>
          <th scope="col" class="thead-top-right p-2">Matrícula</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataRegistradoresMatoGrossoSul.dados?.length > 0; else noDataRegistradoresMatoGrossoSul">
          <tr class="text-center table-info" *ngFor="let registradores of dataRegistradoresMatoGrossoSul.dados">
            <td>{{registradores?.estado}}</td>
            <td>{{registradores?.cartorio}}</td>
            <td>{{registradores?.matricula}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataRegistradoresMatoGrossoSul>
          <tr>
            <td colspan="3" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingRegistradoresMatoGrossoSul && exibeMensagemMatoGrossoSul">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarRegistradoresMatoGrossoSul">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getRegistradoresMatoGrossoSul(idPesquisa, 209)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataRegistradoresMatoGrossoSul.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingRegistradoresMatoGrossoSul"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeVeiculos">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">directions_car</mat-icon>&nbsp; Veiculos
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingVeiculos && !exibeMensagemVeiculos"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Marca</th>
          <th scope="col" class="thead p-2">Placa</th>
          <th scope="col" class="thead p-2">Ano Fabricação</th>
          <th scope="col" class="thead p-2">Ano Modelo</th>
          <th scope="col" class="thead p-2">Renavam</th>
          <th scope="col" class="thead p-2">Município</th>
          <th scope="col" class="thead p-2">UF</th>
          <th scope="col" class="thead p-2">Chassi</th>
          <th scope="col" class="thead-top-right p-2">N° Motor</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataVeiculos.dados?.length > 0; else noDataVeiculos">
          <tr class="text-center table-info" *ngFor="let veiculo of dataVeiculos.dados">
            <td>{{veiculo?.marca}}</td>
            <td>{{veiculo?.placa}}</td>
            <td>{{veiculo?.anoFabricacao}}</td>
            <td>{{veiculo?.anoModelo}}</td>
            <td>{{veiculo?.renavam}}</td>
            <td>{{veiculo?.cidade}}</td>
            <td>{{veiculo?.uf}}</td>
            <td>{{veiculo?.chassi}}</td>
            <td>{{veiculo?.nrMotor}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataVeiculos>
          <tr>
            <td colspan="9" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingVeiculos && exibeMensagemVeiculos">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarVeiculos">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getVeiculos(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataVeiculos.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingVeiculos"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeMinerios">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">terrain</mat-icon>&nbsp; Minerios
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingMinerios && !exibeMensagemMinerios"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Requerimento</th>
          <th scope="col" class="thead p-2">Fase Atual</th>
          <th scope="col" class="thead p-2">Nome Titular</th>
          <th scope="col" class="thead p-2">Municípios</th>
          <th scope="col" class="thead p-2">Substâncias</th>
          <th scope="col" class="thead p-2">Tipo de Uso</th>
          <th scope="col" class="thead-top-right p-2">Situação</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataMinerios.dados?.length > 0; else noDataMinerios">
          <tr class="text-center table-info" *ngFor="let minerio of dataMinerios.dados">
            <td>{{minerio?.tipoRequerimento}}</td>
            <td>{{minerio?.faseAtual}}</td>
            <td>{{minerio?.nomeTitular}}</td>
            <td>{{minerio?.municipios}}</td>
            <td>{{minerio?.subistancias}}</td>
            <td>{{minerio?.tipoDeUso}}</td>
            <td>{{minerio?.situacao}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataMinerios>
          <tr>
            <td colspan="7" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingMinerios && exibeMensagemMinerios">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarMinerios">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getMinerios(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataMinerios.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingMinerios"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeImovelRequerimento">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Imovel rural Requerimento
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingImovelRequerimento && !exibeMensagemImovelRequerimento"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Nome</th>
          <th scope="col" class="thead p-2">Protocolo do Requerimento</th>
          <th scope="col" class="thead p-2">Tipo de Requerimento</th>
          <th scope="col" class="thead p-2">Data de Abertura</th>
          <th scope="col" class="thead p-2">Nome da Área / Parcela</th>
          <th scope="col" class="thead-top-right p-2">Área (ha)</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataImovelRequerimento.dados?.length > 0; else noDataImovelRequerimento">
          <tr class="text-center table-info" *ngFor="let imovelRequerimento of dataImovelRequerimento.dados ">
            <td>{{imovelRequerimento?.nomeDetentor}}</td>
            <td>{{imovelRequerimento?.protocoloRequerimento}}</td>
            <td>{{imovelRequerimento?.tipoRequerimento}}</td>
            <td>{{imovelRequerimento?.dataAbertura}}</td>
            <td>{{imovelRequerimento?.nomeAreaParcela}}</td>
            <td>{{imovelRequerimento?.areaHA}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataImovelRequerimento>
          <tr>
            <td colspan="6" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingImovelRequerimento && exibeMensagemImovelRequerimento">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarImovelRequerimento">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getImovelRequerimento(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div> 
      <div>
        <p>{{dataImovelRequerimento.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingImovelRequerimento"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeImovelParcela">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Imovel Rural Parcela
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingImovelParcela && !exibeMensagemImovelParcela"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Nome</th>
          <th scope="col" class="thead p-2">Número Matricula</th>
          <th scope="col" class="thead p-2">Nome do Detentor</th>
          <th scope="col" class="thead p-2">Código do Cartório</th>
          <th scope="col" class="thead-top-right p-2">Área (ha)</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataImovelParcela.dados?.length > 0; else noDataImovelParcela">
          <tr class="text-center table-info" *ngFor="let imovelParcela of dataImovelParcela.dados">
            <td>{{imovelParcela?.nome}}</td>
            <td>{{imovelParcela?.matricula}}</td>
            <td>{{imovelParcela?.detentor}}</td>
            <td>{{imovelParcela?.cns}}</td>
            <td>{{imovelParcela?.areaHA}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataImovelParcela>
          <tr>
            <td colspan="5" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingImovelParcela && exibeMensagemImovelParcela">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarImovelParcela">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getImovelParcela(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataImovelParcela.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingImovelParcela"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeImovelSerpro">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">location_city</mat-icon>&nbsp; Imovel Rural Serpro
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingImovelSerpro && !exibeMensagemImovelSerpro"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Código do Imovél</th>
          <th scope="col" class="thead p-2">Denominação do Imóvel</th>
          <th scope="col" class="thead p-2">Município</th>
          <th scope="col" class="thead p-2">UF</th>
          <th scope="col" class="thead p-2">Área Total</th>
          <th scope="col" class="thead p-2">Condição da Pessoa</th>
          <th scope="col" class="thead-top-right p-2">Percentual de Detenção</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataImovelSerpro.dados?.length > 0; else noDataImovelSerpro">
          <tr class="text-center table-info" *ngFor="let imovelSerpro of dataImovelSerpro.dados">
            <td>{{imovelSerpro?.codigoImovel}}</td>
            <td>{{imovelSerpro?.denominacaoImovel}}</td>
            <td>{{imovelSerpro?.codigoMunicipioIBGE}}</td>
            <td>{{imovelSerpro?.uf}}</td>
            <td>{{imovelSerpro?.areaTotal}}</td>
            <td>{{imovelSerpro?.condicaoPessoa}}</td>
            <td>{{imovelSerpro?.percentualDetencao}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataImovelSerpro>
          <tr>
            <td colspan="7" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingImovelSerpro && exibeMensagemImovelSerpro">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarImovelSerpro">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getImovelSerpro(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataImovelSerpro.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingImovelSerpro"></loader-only>
  </mat-expansion-panel>
</mat-accordion>


<mat-accordion *ngIf="exibeAeronaves">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">flight</mat-icon>&nbsp; Aeronaves
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingAeronaves && !exibeMensagemAeronave"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Modelo</th>
          <th scope="col" class="thead p-2">Fabricante</th>
          <th scope="col" class="thead p-2">Proprietário</th>
          <th scope="col" class="thead p-2">CPF / CNPJ</th>
          <th scope="col" class="thead p-2">Matricula</th>
          <th scope="col" class="thead p-2">Operador</th>
          <th scope="col" class="thead p-2">Série</th>
          <th scope="col" class="thead p-2">Dt. Compra Transferência</th>
          <th scope="col" class="thead-top-right p-2">Qtde Passageiro</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataAeronaves.dados?.length > 0; else noDataAeronaves">
          <tr class="text-center table-info" *ngFor="let aeronave of dataAeronaves.dados">
            <td>{{aeronave.dsModelo}}</td>
            <td>{{aeronave.nmFabricante}}</td>
            <td>{{aeronave.proprietario}}</td>
            <td>{{aeronave.cpfCnpj}}</td>
            <td>{{aeronave.matricula}}</td>
            <td>{{aeronave.nmOperador}}</td>
            <td>{{aeronave.nrSerie}}</td>
            <td>{{aeronave.dataCompraTransferencia}}</td>
            <td>{{aeronave.nrPassageiros}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataAeronaves>
          <tr>
            <td colspan="9" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingAeronaves && exibeMensagemAeronave">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarAeronaves">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getAeronave(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataAeronaves.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingAeronaves"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeEmbarcacoes">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">directions_boat</mat-icon>&nbsp; Embarcações
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingEmbarcacoes && !exibeMensagemEmbarcacoes"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Finalistico</th>
          <th scope="col" class="thead-top-right p-2">Instalação Portuária</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataEmbarcacoes.dados?.length > 0; else noDataEmbarcacoes">
          <tr class="text-center table-info" *ngFor="let embarcacao of dataEmbarcacoes.dados">
            <td>{{dataEmbarcacoes.finalistico}}</td>
            <td>{{dataEmbarcacoes.instalacaoPortuaria}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataEmbarcacoes>
          <tr>
            <td colspan="2" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingEmbarcacoes && exibeMensagemEmbarcacoes">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarEmbarcacoes">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getEmbarcacoes(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataEmbarcacoes.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingEmbarcacoes"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeSafra">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">games</mat-icon>&nbsp; Safra
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingSafra && !exibeMensagemSafra"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">Status</th>
          <th scope="col" class="thead p-2">UF</th>
          <th scope="col" class="thead p-2">Nome</th>
          <th scope="col" class="thead p-2">Município</th>
          <th scope="col" class="thead p-2">Seguradora</th>
          <th scope="col" class="thead p-2">Proposta</th>
          <th scope="col" class="thead p-2">Apólice</th>
          <th scope="col" class="thead-top-right p-2">Atividade</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataSafra.dados?.length > 0; else noDataSafra">
          <tr class="text-center table-info" *ngFor="let safra of dataSafra.dados">
            <td>{{safra.safra}}</td>
            <td>{{safra.uf}}</td>
            <td>{{safra.nome}}</td>
            <td>{{safra.municipio}}</td>
            <td>{{safra.seguradora}}</td>
            <td>{{safra.proposta}}</td>
            <td>{{safra.apolice}}</td>
            <td>{{safra.atividade}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataSafra>
          <tr>
            <td colspan="8" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingSafra && exibeMensagemSafra">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarSafra">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getSafra(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataSafra.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingSafra"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="exibeImovelUniao">
  <mat-expansion-panel class="mt-3 py-2" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class=" title-cordion mb-0 d-flex align-items-center">
          <mat-icon class="detech-icon">account_balance</mat-icon>&nbsp; Patrimonio da União
        </h3>
      </mat-panel-title>

    </mat-expansion-panel-header>
    <table class="table" *ngIf="!isLoadingImovelUniao && !exibeMensagemImovelUniao"> 
      <thead class="thead-light">
        <tr class="text-center">
          <th scope="col" class="thead-top-left p-2">GRPU Responsável</th>
          <th scope="col" class="thead p-2">Situação</th>
          <th scope="col" class="thead p-2">Tipo Terreno</th>
          <th scope="col" class="thead p-2">Fração Terreno Total</th>
          <th scope="col" class="thead p-2">Denominação Área</th>
          <th scope="col" class="thead p-2">Tipo Ocupação</th>
          <th scope="col" class="thead p-2">Situado em Ilha</th>
          <th scope="col" class="thead p-2">CPF / CNPJ Responsável</th>
          <th scope="col" class="thead p-2">Nome Responsável</th>
          <th scope="col" class="thead-top-right p-2">Endereço Responsável</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataImovelUniao.dados?.length > 0; else noDataImovelUniao">
          <tr class="text-center table-info" *ngFor="let imovelUniao of dataImovelUniao.dados">
            <td>{{imovelUniao?.grpu_responsavel}}</td>
            <td>{{imovelUniao?.situacao}}</td>
            <td>{{imovelUniao?.tipo_terreno}}</td>
            <td>{{imovelUniao?.fracao_terreno_total}}</td>
            <td>{{imovelUniao?.denominacao_area}}</td>
            <td>{{imovelUniao?.tipo_ocupacao}}</td>
            <td>{{imovelUniao?.situado_em_ilha}}</td>
            <td>{{imovelUniao?.responsavel_cpfCnpj}}</td>
            <td>{{imovelUniao?.responsavel_nome}}</td>
            <td>{{imovelUniao?.responsavel_endereco}}</td>
          </tr>
        </ng-container>
        <ng-template #noDataImovelUniao>
          <tr>
            <td colspan="10" class="text-center">Não há resultados disponíveis</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="d-flex justify-content-center text-center flex-column" *ngIf="!isLoadingImovelUniao && exibeMensagemImovelUniao">
      <div class="d-flex justify-content-end" *ngIf="exibeReprocessarImovelUniao">
        <button mat-flat-button class="gs-botaoAzulPadrao m-3" (click)="getImovelUniao(idPesquisa)">Refresh <mat-icon>cached
        </mat-icon></button>
      </div>
      <div>
        <p>{{dataImovelUniao.mensagem}}</p>
      </div>
    </div>
     <loader-only *ngIf="isLoadingImovelUniao"></loader-only>
  </mat-expansion-panel>
</mat-accordion>

<div class="text-center p-3 header">Solicitação de pesquisa {{produto}}</div>
<div *ngIf="!isLote">
  <div class="text-center mensagem-qualificada" *ngIf="exibirMensagemQualificada()">
    <p class="p-0 mb-0">Sua solicitação contém pesquisas qualificadas que podem demorar até 7 dias</p>
    <p class="p-0 mb-0">*Os cartórios podem não responder ou até demorar mais do que o esperado</p>
  </div>
  <div class="mt-5 content-values">
  
    <h3 class="title-tipo-pesquisa mt-1" *ngIf="data.dados?.valorPesquisaAdicional > 0">Pesquisa Prévia</h3>
    <hr class="hr-values" *ngIf="data.dados?.valorPesquisaAdicional > 0">
    <div class="" *ngFor=" let adicionais of data.dados.fontesAdicionaisSelecionadas">
      <div class="parametrosAdicionais d-flex justify-content-between mt-2"
        *ngIf="data.dados?.valorPesquisaAdicional > 0">
        <p class="textConfirm">{{adicionais.descricao}}</p>
        <p class="textConfirm">
          R${{adicionais?.valor !== undefined ? adicionais.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <hr class="hr-values" *ngIf="data.dados?.valorPesquisaAdicional > 0">
    </div>
  
    <h3 class="title-tipo-pesquisa" *ngIf="data.dados?.parametrosSelecionados?.length > 0">Pesquisa Qualificada</h3>
    <hr class="hr-values" *ngIf="data.dados?.parametrosSelecionados?.length > 0">
    <div class="" *ngFor=" let parametros of data.dados.parametrosSelecionados">
      <div class="fontesAdicionais d-flex justify-content-between" *ngIf="data.dados?.parametrosSelecionados?.length > 0">
        <p class="textConfirm">{{parametros.mensagem}}</p>
       <p class="textConfirm">
        R${{parametros?.valor !== undefined ? parametros.valor.toFixed(2) : '-'}}
      </p>
      </div>
      <hr class="hr-values" *ngIf="data.dados?.parametrosSelecionados?.length > 0">
    </div>
  
    <h3 class="title-tipo-pesquisa" *ngIf="parametrosAvulsos?.length > 0">Parâmetros</h3>
  <hr class="hr-values" *ngIf="parametrosAvulsos?.length > 0">
  <div *ngFor="let parametros of parametrosAvulsos">
    <div class="fontesAdicionais d-flex justify-content-between">
      <p class="textConfirm">{{parametros.mensagem}}</p>
     <p class="textConfirm">
        R${{parametros?.valor !== undefined ? parametros.valor.toFixed(2) : '-'}}
      </p>
    </div>
    <hr class="hr-values">
  </div>
  
  
    <div class="d-flex justify-content-between">
      <p class="valorAzul">TOTAL DE CONSUMO DE CRÉDITOS</p>
      <p class="valorAzul">R${{(data.dados?.valorPesquisaAdicional + data.dados?.valorPesquisaParametro).toFixed(2)}}</p>
    </div>
  </div>
</div>

<div *ngIf="isLote">
  <div class="text-center mensagem-qualificada" *ngIf="exibirMensagemQualificadaLote()">
    <p class="p-0 mb-0">Sua solicitação contém pesquisas qualificadas que podem demorar até 7 dias</p>
    <p class="p-0 mb-0">*Os cartórios podem não responder ou até demorar mais do que o esperado</p>
  </div>
  <div class="mt-5 content-values">
  
    <h3 class="title-tipo-pesquisa mt-1" *ngIf="data.somaValores?.valorAdicional > 0">Pesquisa Prévia</h3>
    <hr class="hr-values" *ngIf="data.somaValores?.valorPesquisaAdicional > 0">
    <div class="" *ngFor=" let adicionais of data.somaValores.fontesAdicionaisSelecionadas">
      <div class="parametrosAdicionais d-flex justify-content-between mt-2"
        *ngIf="data.somaValores?.valorAdicional > 0">
        <p class="textConfirm">{{adicionais.descricao}} (x{{ data?.quantidadeDocs }})</p>
        <p class="textConfirm">
          R${{adicionais?.valor !== undefined ? adicionais.valor.toFixed(2) : '-'}}
        </p>
      </div>
      <hr class="hr-values" *ngIf="data.somaValores?.valorAdicional > 0">
    </div>
  
    <h3 class="title-tipo-pesquisa" *ngIf="data.somaValores?.parametrosSelecionados?.length > 0">Pesquisa Qualificada</h3>
    <hr class="hr-values" *ngIf="data.somaValores?.parametrosSelecionados?.length > 0">
    <div class="" *ngFor=" let parametros of data.somaValores.parametrosSelecionados">
      <div class="fontesAdicionais d-flex justify-content-between" *ngIf="data.somaValores?.parametrosSelecionados?.length > 0">
        <p class="textConfirm">{{parametros.mensagem}} (x{{ data?.quantidadeDocs }})</p>
       <p class="textConfirm">
        R${{parametros?.valor !== undefined ? parametros.valor.toFixed(2) : '-'}}
      </p>
      </div>
      <hr class="hr-values" *ngIf="data.somaValores?.parametrosSelecionados?.length > 0">
    </div>
  
    <h3 class="title-tipo-pesquisa" *ngIf="parametrosAvulsos?.length > 0">Parâmetros</h3>
  <hr class="hr-values" *ngIf="parametrosAvulsos?.length > 0">
  <div *ngFor="let parametros of parametrosAvulsos">
    <div class="fontesAdicionais d-flex justify-content-between">
      <p class="textConfirm">{{parametros.mensagem}}</p>
     <p class="textConfirm">
        R${{parametros?.valor !== undefined ? parametros.valor.toFixed(2) : '-'}}
      </p>
    </div>
    <hr class="hr-values">
  </div>
  
  
    <div class="d-flex justify-content-between">
      <p class="valorAzul">TOTAL DE CONSUMO DE CRÉDITOS</p>
      <p class="valorAzul">R${{data.somaValores?.valorPesquisa.toFixed(2)}}</p>
    </div>
  </div>
</div>

<div class="mt-1">
  <div class="d-flex justify-content-between mt-3">
    <button class="btn btn-primary btn-cancel" mat-dialog-close (click)="fecharModal()">Cancelar</button>
    <button class="btn btn-primary btn-go" (click)="realizarPesquisa()">Confirmar</button>
  </div>
</div>
